import styled from "styled-components";

const width = "15.1875rem";
const width_collapse = "3.75rem";
const backgroundColor = "rgba(224, 224, 224, 1)";

const Sidebar = styled.aside`
    width: ${(props) => (props.isOpen ? width : width_collapse)};
    height: calc(100% - 5rem);
    position: absolute;
    top: 5rem;
    padding-top: 0;
    z-index: 99;
    background: #ffffff;
    transition: all 0.2s ease;
    overflow: ${(props) => (props.isOpen ? "auto" : "visible")};
`;
const Div = styled.div`
    ${({ isHeader, isOpen }) =>
        isHeader &&
        `
        height: 5rem;
        width: ${isOpen ? width : width_collapse};
        display: inline-block;
        text-align: left;
        position: absolute;
        left: 0;
        top: 0;
        background: #2e66b7;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
        transition: all .2s ease;
    `}

    ${({ slimScrollDiv }) =>
        slimScrollDiv &&
        `
        position: relative;
        width: auto;
        height: 100%;
    `}

    ${({ nkNavScroll }) =>
        nkNavScroll &&
        `
        width: auto;
        height: 100%;
    `}
`;

const Ul = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    background: #fafafa;

    ${({ menu }) =>
        menu &&
        `
        display: flex;
        flex-direction: column;
        background: #ffffff;
    `}

    ${({ submenu, isOpen }) =>
        submenu &&
        isOpen &&
        `
        display: flex;
        flex-direction: column;
        background: #ffffff;
    `}

    ${({ submenu, isOpen, open }) =>
        submenu &&
        isOpen &&
        !open &&
        `
        display: none;
    `}

    ${({ submenu, isOpen }) =>
        submenu &&
        !isOpen &&
        `
        position: absolute;
        left: ${width_collapse};
        top: 2.9375rem;
        width: calc(${width} - ${width_collapse});
        z-index: 1001;
        display: none;
        padding-left: 1px;
    `}
`;

const Li = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    list-style: none;

    &:hover {
        background-color: ${backgroundColor};
    }

    ${({ nested }) =>
        !nested &&
        `
        font-size: 1rem;
        letter-spacing: 0.05rem;
        color: #abafb3;
    `}

    ${({ nested, isOpen }) =>
        nested &&
        isOpen &&
        `
        padding-left: 0.625rem;
    `}

    ${({ nested, isOpen }) =>
        nested &&
        !isOpen &&
        `
        display: none;
    `}

    ${({ nested, isOpen, cols }) =>
        !nested &&
        !isOpen &&
        `
        &:hover > ${Span} {
            display: block;
            width: calc((${width} * ${cols}) + ${width_collapse});
            background-color: ${backgroundColor};
            z-index: 1001;

            & > ${Span} {
                display: inline-block;
                padding: unset;
                padding-left: 1.6875rem;
            }
        }

        &:hover > ${Ul} {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: calc(3.6rem * 6);
            overflow: visible;
            position: absolute;
            left: ${width_collapse};
            top: 3.5rem;
            width: auto;
            z-index: 1001;
            padding-left: 0px;
            visibility: visible;

            & > ${Li} {
                display: flex;
                width: ${width};
                background-color: #fafafa;

                & > ${Span} {
                    display: block;

                    & > ${Span} {
                        display: unset;
                        padding: unset;
                    }
                }
            }
        }
    `}
`;

const Span = styled.span`
    position: relative;
    padding: 0.8125rem 1.25rem;
    outline-width: 0;
    transition: all 0.3s ease-out;
    color: #464a53;
    cursor: pointer;

    ${({ navText, isOpen }) =>
        navText &&
        !isOpen &&
        `
        display: none;
    `}

    ${({ navText, isOpen }) =>
        navText &&
        isOpen &&
        `
        padding: 0;
    `}

    ${({ hasArrow, isOpen, open, isDisabled }) =>
        hasArrow &&
        isOpen &&
        !isDisabled &&
        `
        &::after {
            position: absolute;
            content: "";
            width: 0.5em;
            height: 0.5em;
            border-width: 1px 0 0 1px;
            border-style: solid;
            border-color: initial;
            right: 1.5em;
            transform: rotate(${open ? 225 : 135}deg) translateY(-50%);
            transform-origin: top;
            top: 50%;
            transition: all 0.3s ease-out;
        }
    `}

    ${({ nested }) =>
        !nested &&
        `
        padding: 0.8125rem 1.25rem;
    `}

    ${({ navText, isDisabled }) =>
        navText &&
        isDisabled &&
        // isOpen &&
        `
        opacity: 0.5;
    `}

    & > svg {
        padding: 0 0.4375rem 0 0;
    }
`;

const Sidebars = {
    Sidebar,
    Div,
    Ul,
    Li,
    Span,
};

export default Sidebars;
