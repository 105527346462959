import * as Yup from "yup";

export default Yup.object().shape({
    question_type: Yup.object().shape({
        value: Yup.string().required("Campo obrigatório."),
        label: Yup.object(),
    }),
    name: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    description: Yup.string().nullable(),
    themes: Yup.array().of(
        Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
        })
    ),
    tags: Yup.array().of(
        Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
        })
    ),
    is_active: Yup.boolean(),
    has_evidence: Yup.boolean(),

    offeredAnswers: Yup.array().of(
        Yup.object().shape({
            uuid: Yup.string().required("Campo obrigatório."),
            order: Yup.number().required("Campo obrigatório."),
            percent: Yup.number()
                .typeError("O valor deve ser um número")
                .test(
                    "maxDigits",
                    "O valor deve ter no máximo 2 casas decimais.",
                    (number) => Number.isInteger(number * 10 ** 2)
                )
                .min(0, "O valor mínimo deve ser 0.")
                .max(100, "O valor máximo deve ser 100.")
                .required("Campo obrigatório."),
            name: Yup.string().required("Campo obrigatório."),
            annul_question: Yup.boolean(),
            has_feedback: Yup.boolean(),
            feedback: Yup.string().nullable(),
        })
    ),
});
