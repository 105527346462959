import React from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Tabs, Tab } from "@material-ui/core";
import { MdUndo, MdSave } from "react-icons/md";

import api from "../../../services/api";

import Form from "../../../components/Form";
import Field from "../../../components/Field";

import schema from "./validation";
import TabForm from "./components/Form";
import TabConfig from "./components/Config";
import TabPage from "./components/Page";
import TabDevolutive from "./components/Devolutive";
import ThemeModal from "./components/Page/components/ThemeModal";
import QuestionModal from "./components/Page/components/QuestionModal";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ display: value !== index ? "none" : "block" }}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();

  const methods = useForm({ resolver: yupResolver(schema) });

  const [tabActive, setTabActive] = React.useState(0);

  const [showThemeModal, setShowThemeModal] = React.useState(false);
  const [themeSelected, setThemeSelected] = React.useState(null);
  const [showQuestionModal, setShowQuestionModal] = React.useState(false);
  const [questionSelected, setQuestionSelected] = React.useState(null);
  const [themeModalProps, setThemeModalProps] = React.useState({});
  const [questionModalProps, setQuestionModalProps] = React.useState({});


  const onSubmit = async (data) => {
    data.confirmation_message_mode = data.confirmation_message_mode.value;

    if (id) {
      await api.patch(`forms/${id}/`, data);
    } else {
      await api.post(`forms/`, data);
    }
    props.history.push(`/forms`);
  };

  const onInvalid =  (e )=>{
    if(e.description!== undefined){
      handleTabChange(null, 2)
    } else{
      alert('Erro ao salvar os dados, verifique o preenchimento dos campos e tente novamente');
    }
  } 

  const handleButtonBack = () => {
    props.history.push("/forms");
  };

  const handleTabChange = (e, newValue) => {
    setTabActive(newValue);
  };


  return (
    <>
      <FormProvider {...methods}  >
        <Form onSubmit={methods.handleSubmit(onSubmit, onInvalid)}>
          <Paper className={classes.paper}>
            <Tabs
              value={tabActive}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Formulário" {...a11yProps(0)} />
              <Tab label="Devolutivas" {...a11yProps(1)} />
              <Tab label="Configurações" {...a11yProps(2)} />
              <Tab label="Pergunta/Tema" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={tabActive} index={0}>
              <TabForm />
            </TabPanel>
            <TabPanel value={tabActive} index={1}>
              <TabDevolutive />
            </TabPanel>
            <TabPanel value={tabActive} index={2}>
              <TabConfig />
            </TabPanel>
            <TabPanel value={tabActive} index={3}>
              <TabPage
                themeSelected={themeSelected}
                setThemeSelected={setThemeSelected}
                setShowThemeModal={setShowThemeModal}
                themeModalProps={themeModalProps}
                setThemeModalProps={setThemeModalProps}
                questionSelected={questionSelected}
                setQuestionSelected={setQuestionSelected}
                setShowQuestionModal={setShowQuestionModal}
                questionModalProps={questionModalProps}
                setQuestionModalProps={setQuestionModalProps}
              />
            </TabPanel>
          </Paper>

          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Field.Button
                label="Voltar"
                onClick={handleButtonBack}
                startIcon={<MdUndo />}
              />
              <Field.Button
                type="submit"
                label="Salvar"
                color="primary"
                startIcon={<MdSave />}
              />
            </Grid>
          </Grid>
        </Form>
      </FormProvider>

      {showThemeModal && (
        <ThemeModal
          open={showThemeModal}
          setOpen={setShowThemeModal}
          selected={themeSelected}
          setSelected={setThemeSelected}
          {...themeModalProps}
        />
      )}

      {showQuestionModal && (
        <QuestionModal
          open={showQuestionModal}
          setOpen={setShowQuestionModal}
          selected={questionSelected}
          setSelected={setQuestionSelected}
          {...questionModalProps}
        />
      )}
    </>
  );
};

export default Index;
