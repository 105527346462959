import * as Yup from "yup";

export default Yup.object().shape({
  application_type: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  name: Yup.string()
    .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    .required("Campo obrigatório."),
  form_type: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  interviewed_type: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  value: Yup.string().required("Campo obrigatório."),
  description: Yup.string(),
  confirmation_message: Yup.string(),
  is_readonly: Yup.boolean(),
  only_company: Yup.boolean(),
  is_active: Yup.boolean(),

  time_reply: Yup.number().typeError("O valor dever um número"),
  email_reminder: Yup.string(),
  is_public: Yup.boolean(),
});
