import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./components/List";

const Index = ({ match }) => {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={List} />
        </Switch>
    );
};

export default Index;
