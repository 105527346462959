import React from "react";
import { useParams } from "react-router-dom";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import RelatedVars from "../../../domain/MailModel/RealatedVars";
import Field from "../../../components/Field";
import { MdUndo } from "react-icons/md";

const Form = (props) => {
  const { id } = useParams();

  const handleButtonBack = () => {
    props.history.push("/mail_models");
  };

  return (
    <>
      <Sections.Form>
        <Headers.Form>
          {id ? "Editar" : "Adicionar"} Modelo de e-mail
        </Headers.Form>
      </Sections.Form>

      <Article>
        <RelatedVars {...props} />
      </Article>
      <Field.Button
        label="Voltar"
        onClick={handleButtonBack}
        startIcon={<MdUndo />}
      />
    </>
  );
};

export default Form;
