import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import arrayMove from "array-move";
import { Grid, Paper } from "@material-ui/core";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Field from "../../../components/Field";
import { MdUndo } from "react-icons/md";

import api from "../../../services/api";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

// eslint-disable-next-line no-unused-vars
const SortableItem = SortableElement(({ value, classes }) => (
  <Paper className={classes.paper}>{value}</Paper>
));

const SortableList = SortableContainer(({ resultOfferedAnswer, classes }) => {
  if (resultOfferedAnswer) {
    // return <div></div>;
    return (
      <ul>
        {resultOfferedAnswer.map(({ name, uuid }, index) => (
          <SortableItem
            key={`item-${uuid}`}
            index={index}
            classes={classes}
            value={name}
          />
        ))}
      </ul>
    );
  }
});
const Index = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [resultQuestion, setResultQuestion] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [resultOfferedAnswer, setResultOfferedAnswern] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const requestQuestion = await api.get(`questions/${id}/`);
          const requestOfferedAnswer = await api.get(`offereds/${id}/answers/`);
          setResultQuestion(requestQuestion.data);
          setResultOfferedAnswern(requestOfferedAnswer.data.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    let reordered = resultOfferedAnswer;

    reordered = arrayMove(reordered, oldIndex, newIndex);
    reordered.forEach((answer, ind) => {
      let order = ind + 1;
      reordered[ind].order = order;
    });

    setResultOfferedAnswern(reordered);
    let response = await api.patch(`/questions/${id}/order/`,{offeredAnswers: resultOfferedAnswer});
    if(response.data.error==true){
      alert('Erro ao salvar');
    }
  };

  const handleButtonBack = () => {
    props.history.push("/questions");
  };
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container item xs={12}>
          <h3>{resultQuestion.name}</h3>
          <h5>{resultQuestion.description}</h5>
        </Grid>
      </Paper>
      <Paper className={classes.paper} key={resultQuestion.uuid}>
        <h5>Alternativas</h5>
        <SortableList
          key="{index}"
          resultOfferedAnswer={resultOfferedAnswer}
          classes={classes}
          onSortEnd={onSortEnd}
        ></SortableList>
      </Paper>
     
      <Field.Button
              label="Voltar"
              onClick={handleButtonBack}
              startIcon={<MdUndo />}
            />
    </>
  );
};

export default Index;
