import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { MdDelete, MdUndo } from "react-icons/md";

import api from "../../../services/api";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import Field from "../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Delete = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [user, setUser] = React.useState();
  console.log();

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`users/${id}/`);

          setUser(result.data);
          console.log(result);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const getBackPush = () => {
    if (props.match.path == "/users/consultants/delete/:id") {
      return "/users/consultants";
    } else {
      return "/users";
    }
  };

  const handleButtonBack = () => {
    props.history.push(getBackPush());
  };

  const handleRemove = async () => {
    await api.delete(`users/${id}/`);

    props.history.push(getBackPush());
  };

  return (
    <>
      <Sections.Delete>
        <Headers.Delete>Remover Usuário</Headers.Delete>
      </Sections.Delete>

      <Article>
        <Paper className={classes.paper}>
          <Grid container item spacing={3} xs={12} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant="button">
                Deseja excluir o usuário &quot;{user?.username}&quot; ?
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            label="Remover"
            onClick={handleRemove}
            color="secondary"
            startIcon={<MdDelete />}
          />
        </Grid>
      </Article>
    </>
  );
};

export default Delete;
