import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

import { Context } from "../../contexts/AuthContext";

import Main from "./components/Main";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

const Index = (props) => {
  const { authenticated } = React.useContext(Context);
  const [open, setOpen] = React.useState(true);
  const [visible, setVisible] = React.useState(true);
  const theme = createTheme({}, ptBR);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const handleAuthenticated = () => {
      setVisible(authenticated);
    };

    handleAuthenticated();
  }, [authenticated]);
  if (authenticated == true) {
    return (
      <>
        <CssBaseline />

        <ThemeProvider theme={theme}>
          {visible ? (
            <>
              <Header isOpen={open} onOpen={handleOpen} onClose={handleClose} />
              <Sidebar isOpen={open} />
            </>
          ) : null}

          <Main isVisible={visible} isOpen={open} {...props} />
        </ThemeProvider>
      </>
    );
  }else{
      return (<>
      <div {...props} />
          
      </>);
  }
};

export default Index;
