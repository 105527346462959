import React from "react";
import { useParams } from "react-router-dom";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import MailModelForm from "../../../domain/MailModel/Form";

const Form = (props) => {
    const { id } = useParams();

    return (
        <>
            <Sections.Form>
                <Headers.Form>{id ? "Editar" : "Adicionar"} Modelo de e-mail</Headers.Form>
            </Sections.Form>

            <Article>
                <MailModelForm {...props} />
            </Article>
        </>
    );
};

export default Form;
