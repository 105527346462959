import React from "react";

import Main from "../../../../components/Main";

const Index = (props) => {
    const { isOpen } = props;

    return <Main isOpen={isOpen}>{props.children}</Main>;
};

export default Index;
