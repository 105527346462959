import styled from "styled-components";
import { TextField } from "@material-ui/core";
// import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";

const Text = styled(TextField)``;

const Mask = styled(MaskedInput)`
    ${Text}

    ${({ error }) =>
        error &&
        `
        input {
            border: #f00 1px solid;
            border-radius: 4px;
        }
    `}
`;

const Fields = {
    Text,
    Mask,
};

export default Fields;
