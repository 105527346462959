import React from "react";
import { useForm } from "react-hook-form";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputAdornment,
  Paper,
  Typography,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
} from "@material-ui/core";
import { MdClose, MdDelete, MdSave } from "react-icons/md";
import CustomDataGrid from "../../../../components/DataGrid";

import api from "../../../../services/api";

import Field from "../../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    maxHeight: "100vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "640px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Index = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open);
  const { uuid } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [painOptions, setPainOptions] = React.useState([]);
  const [reload, setReload] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (open) {
        try {
          const result = await api.get("pains/");

          appendPainOptions(result.data.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [open]);

  const appendPainOptions = (params) => {
    let options = [];

    params.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setPainOptions(options);
  };

  const handleClose = () => {
    props.setShowPainModal(false);
    setOpen(false);
  };

  const handlePainRemove = async (id) => {
    try {
      await api.delete(`offereds/answers/${props.uuid}/pains/${id}/`);

      setReload(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    await api.post(`offereds/answers/${props.uuid}/pains/`, { data });

    setReload(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      filterable: false,
      sortable: false,
    },
    {
      field: "pain_name",
      headerName: "Dor",
      filterable: false,
      flex: 1,
    },
    {
      field: "percent",
      headerName: "Constatação",
      type: "number",
      filterable: false,
      width: 180,
      renderCell: (params) => (
        <div>
          {parseInt(params.value)} %
        </div>
      )
    },
    {
      field: "hint",
      headerName: "Feedback",
      filterable: false,
      flex: 1,
    },
    {
      field: "uuid",
      headerName: "Ações",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handlePainRemove(params.value)}
          color="secondary"
        >
          <MdDelete />
        </IconButton>
      ),
    },
  ];

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        disableEnforceFocus
        open={open}
        fullWidth
        maxWidth="lg"
        className={classes.root}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Dores
        </DialogTitle>

        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={0}>
              <Paper className={classes.paper}>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={10}>
                    <Field.Select
                      control={control}
                      name="pain"
                      label="Dor"
                      errors={errors}
                      options={painOptions}
                      placeholder="Selecione a Dor"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field.NumberMask
                      control={control}
                      name="percent"
                      label="Constatação %"
                      errors={errors}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        if (e.target.value > 100) {
                          setValue("percent", 100);
                        } else {
                          setValue("percent", e.target.value);
                        }
                      }}
                      mask={{
                        allowDecimal: true,
                        decimalSymbol: "",
                        decimalLimit: 0,
                        requireDecimal: false,
                        integerLimit: 3,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Field.Text
                      control={control}
                      name="hint"
                      label="Feedback"
                      errors={errors}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Field.Button
                      type="submit"
                      label="Incluir"
                      color="primary"
                      startIcon={<MdSave />}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </form>

          <CustomDataGrid
            columns={columns}
            path={`offereds/answers/${uuid}/pains/`}
            reload={reload}
            setReload={setReload}
            modal={true}
          />
        </DialogContent>

        <DialogActions>
          <Field.Button
            label="Fechar"
            onClick={handleClose}
            color="default"
            startIcon={<MdSave />}
            autoFocus
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
