import React from "react";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";

const List = () => {
    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            filterable: false,
            sortable: false,
        },
        { field: "name", headerName: "Nome", flex: 1 },
        { field: "description", headerName: "Descrição", flex: 1 },
        {
            field: "modified",
            headerName: "Modificado em",
            type: "date",
            width: 180,
            valueFormatter: (params) =>
                new Intl.DateTimeFormat("default", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                }).format(new Date(params.value)),
        },
    ];

    return (
        <>
            <Sections.List>
                <Headers.List>Tipos de Perguntas</Headers.List>
            </Sections.List>

            <Article>
                <CustomDataGrid columns={columns} path={"questions/types/"} />
            </Article>
        </>
    );
};

export default List;
