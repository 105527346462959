import React from "react";
// import { useParams } from "react-router";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import OrderAnswer from "../../../domain/Question/OrderAnswers";

const OrderAnswers = (props) => {
    // const { id } = useParams();

    return (
        <>
            <Sections.Form>
                <Headers.Form>
                    Ordernar Alternativas
                </Headers.Form>
            </Sections.Form>

            <Article>
                <OrderAnswer {...props} />
            </Article>
        </>
    );
};

export default OrderAnswers;
