import * as Yup from "yup";
import { validaCPF } from "./ValidarCPFCNPJ";

export default Yup.object().shape({
  first_name: Yup.string()
    .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    .required("Campo obrigatório."),
  last_name: Yup.string()
    .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    .required("Campo obrigatório."),
  email: Yup.string()
    .email("Informe um email válido.")
    .required("Campo obrigatório."),
  document: Yup.string()
    .required("CPF é obrigatório")
    .test("test-invalid-cpf", "CPF inválido", (cpf) => validaCPF(cpf))
    .transform(value => value.replace(/[^\d]/g, ''))
    .min(11, "O CPF precisa ter 14 caracteres"),
  password: Yup.string()
    .required("A senha é obrigatória")
    .min(6, "A senha precisa de no minimo 6 caracteres"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password")],
    "As senhas não estão iguais"
  ),
});
