/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import Field from "../../../components/Field";
import { IconButton } from "@material-ui/core";

const List = (props) => {
    const { path } = props.match;
    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            filterable: false,
            sortable: false,
        },
        { field: "name", headerName: "Nome", flex: 1 },
        { field: "url", headerName: "Url", flex: 1 },
        {
            field: "uuid",
            headerName: "Ações",
            filterable: false,
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <>
                    <Link to={`${path}/update/${params.value}`}>
                        <IconButton size="small" color="primary">
                            <MdEdit size={24} />
                        </IconButton>
                    </Link> 
                   
                    <Link to={`${path}/delete/${params.value}`}>
                        <IconButton size="small" color="secondary">
                            <MdDelete size={24} />
                        </IconButton>
                    </Link>
                </>
            ),
        },
      
    ];

    return (
        <>
            <Sections.List>
                <Headers.List>Hooks</Headers.List>
                <Link to={`${path}/create`}>
                    <Field.Button
                        label="Novo Hook"
                        color="primary"
                        startIcon={<MdAdd />}
                    />
                </Link>
            </Sections.List>

            <Article>
                <CustomDataGrid columns={columns} path={"hooks/"} />
            </Article>
        </>
    );
};

export default List;
