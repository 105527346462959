/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Form from "../../../components/Form";
import { Grid, Paper } from "@material-ui/core";
import api from "../../../services/api";
import Field from "../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
  editorBox: {
    zIndex: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [mailModel, setMailModel] = React.useState();
  const [mailModelVariables, setMailModelVariables] = React.useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({});

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`mail_model/${id}/`);
          const resultVars = await api.get(`mail_model_variable/`);

          setMailModel(result.data);
          setMailModelVariables(resultVars.data.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      let variables = [];
      Object.keys(data).forEach((resp, i) => {
        if (data[resp] == true) {
          let varsSelected = mailModelVariables.find((vars, ind) => {
            return vars.uuid == resp;
          });
          variables.push(varsSelected.id);
        }
      });
      let dataTs = mailModel;
      dataTs.mail_model_variables = variables;
      setMailModel(dataTs);
      await api.patch(`mail_model/${id}/`, dataTs);
    } else {
      alert("Erro!");
    }

    // props.history.push("/mail_models");
  };

  const verifyChecked = (id) => {
    let checked = mailModel.mail_model_variables.find((varId) => {
      return varId === id;
    });
    if (checked !== undefined) {
      return true;
    }
    return false;
  };

  const handleChange = (event) => {
    setValue(event.target.name, !getValues(event.target.name));
    onSubmit(getValues());
   
    // event.target.checked;

    // setValue([event.target.name], event.target.checked);
  };
  // console.log(mailModelVariables);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <h3>Defina as variaveis disponíveis no modelo</h3>
        {mailModelVariables?.map((mmv, i) => (
          <>
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name={mmv.uuid}
                label={mmv.alias}
                errors={errors}
                color="primary"
                defaultValue={verifyChecked(mmv.id)}
                onChange={handleChange}
              />
            </Grid>
            {/* <pre>{JSON.stringify(mmv, null, 2)}</pre> */}
          </>
        ))}
        
      </Paper>
    </Form>
  );
};

export default Index;
