import React from "react";
import { Link } from "react-router-dom";
import { MdEdit, MdDelete, MdAdd } from "react-icons/md";
import { IconButton } from "@material-ui/core";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";
import Field from "../../../components/Field";

const List = (props) => {
  const { path } = props.match;
  const columns = [
    {
      field: "code",
      headerName: "ID",
      type: "number",
      filterable: false,
      sortable: true,
    },
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "color",
      headerName: "Cor",
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const spanStyle = {
          height: "25px",
          width: "25px",
          float: "left",
          marginTop: "12%",
          marginRight: "5px",
          border: "#CCC 1px solid",
          background: params.formattedValue,
        };

        return (
          <div>
            <div style={spanStyle}>&nbsp;</div>
            {params.formattedValue}
          </div>
        );
      },
    },
    {
      field: "modified",
      headerName: "Modificado em",
      type: "date",
      width: 180,
      valueFormatter: (params) =>
        new Intl.DateTimeFormat("default", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }).format(new Date(params.value)),
    },

    {
      field: "uuid",
      headerName: "Ações",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <>
          <Link to={`${path}/update/${params.value}`}>
            <IconButton size="small" color="primary">
              <MdEdit size={24} />
            </IconButton>
          </Link>
          <Link to={`${path}/delete/${params.value}`}>
            <IconButton size="small" color="secondary">
              <MdDelete size={24} />
            </IconButton>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Sections.List>
        <Headers.List>Temas</Headers.List>
        <Link to={`${path}/create`}>
          <Field.Button
            label="Novo Tema"
            color="primary"
            startIcon={<MdAdd />}
          />
        </Link>
      </Sections.List>

      <Article>
        <CustomDataGrid columns={columns} path={"themes/"} />
      </Article>
    </>
  );
};

export default List;
