/* eslint-disable no-unused-vars */
import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { MdAdd, MdUndo, MdSave, MdContentCopy } from "react-icons/md";

import {AnswersList} from './component/Answers'

import api from "../../../services/api";

import schema from "./validation";
import Form from "../../../components/Form";
import Field from "../../../components/Field";
import Headers from "../../../components/Header";
import SolutionModal from "../../../pages/Question/components/SolutionModal";
import PainModal from "../../../pages/Question/components/PainModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      is_active: true,
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: offeredAnswersFields,
    append: offeredAnswersAppend,
    // insert: offeredAnswersInsert,
    remove: offeredAnswersRemove,
  } = useFieldArray({
    control,
    name: "offeredAnswers",
    keyName: "uuid",
  });

  const [questionTypeOptions, setQuestionTypeOptions] = React.useState([]);
  const [themeOptions, setThemeOptions] = React.useState([]);
  const [tagOptions, setTagOptions] = React.useState([]);
  const [hasOfferedAnswer, setHasOfferedAnswer] = React.useState(false);
  const [solutionModalProps, setSolutionModalProps] = React.useState("");
  const [showSolutionModal, setShowSolutionModal] = React.useState(false);
  const [painModalProps, setPainModalProps] = React.useState("");
  const [showPainModal, setShowPainModal] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const resultQuestionType = await api.get("questions/types/");
        appendQuestionTypeOptions(resultQuestionType.data.data);

        const resultTheme = await api.get("themes/");
        appendThemeOptions(resultTheme.data.data);

        const resultTag = await api.get("tags/");
        appendTagOptions(resultTag.data.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const resultQuestion = await api.get(`questions/${id}/`);

          setValue("name", resultQuestion.data.name);
          setValue("description", resultQuestion.data.description);
          setValue(
            "question_type",
            appendQuestionType(resultQuestion.data.question_type)
          );
          setValue("is_active", resultQuestion.data.is_active);
          setValue("has_evidence", resultQuestion.data.has_evidence);
          setValue("themes", appendThemes(resultQuestion.data.themes));
          setValue("tags", appendTags(resultQuestion.data.tags));

          setHasOfferedAnswer(
            resultQuestion.data.question_type.has_offered_answer
          );

          const resultOfferedAnswer = await api.get(`offereds/${id}/answers/`);

          setValue("offeredAnswers", resultOfferedAnswer.data.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const appendQuestionTypeOptions = (params) => {
    let options = [];

    params.map((value) =>
      options.push({
        value: value?.uuid,
        label: (
          <div data-bs-toggle="tooltip" title={value?.description}>
            {value?.name}
          </div>
        ),
      })
    );

    setQuestionTypeOptions(options);
  };

  const appendThemeOptions = (params) => {
    let options = [];

    params.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setThemeOptions(options);
  };

  const appendTagOptions = (params) => {
    let options = [];

    params.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setTagOptions(options);
  };

  const appendQuestionType = (params) => {
    let options = {
      value: params?.uuid,
      label: (
        <div data-bs-toggle="tooltip" title={params?.description}>
          {params?.name}
        </div>
      ),
    };

    return options;
  };

  const appendThemes = (params) => {
    let options = [];

    params.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    return options;
  };

  const appendTags = (params) => {
    let options = [];

    params.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    return options;
  };

  const onSubmit = async (data) => {
    // console.log(data);
    // return false;
    if (id) {
      await api.patch(`questions/${id}/`, data);

      props.history.push("/questions");
    } else {
      const resultAdd = await api.post(`questions/`, data);

      props.history.push(`/questions/update/${resultAdd.data.uuid}`);
    }
  };

  const handleButtonClone = async () => {
    const result = await api.get(`questions/${id}/clone/`);

    alert(`Pergunta "${result.data.name}" clonada com sucesso `);

    props.history.push(`/questions/update/${result.data.uuid}`);
  };

  const handleButtonBack = () => {
    props.history.push("/questions");
  };

  const handleButtonNew = () => {
    props.history.push("/questions/create");
  };

  const handleOfferedAnswersAppend = async () => {
    const order = offeredAnswersFields.length + 1;

    const resultOfferedAnswer = await api.post(`offereds/${id}/answers/`, {
      order: order,
      name: "Alternativa",
      percent: 0,
      annul_question: false,
    });

    offeredAnswersAppend(resultOfferedAnswer.data);
  };

  const handleOfferedAnswersRemove = async (index) => {
    
    const answer = offeredAnswersFields[index].uuid;

    await api.delete(`offereds/${id}/answers/${answer}/`);

    offeredAnswersRemove(index);
  };

  const handleShowSolutionModal = (item, index) => {
    setSolutionModalProps({ ...item, index });
    setShowSolutionModal(true);
  };

  const handleShowPainModal = (item, index) => {
    setPainModalProps({ ...item, index });
    setShowPainModal(true);
  };


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.paper}>
          <Grid container item spacing={3} xs={12} className={classes.grid}>
            <Grid item xs={12}>
              <Field.Select
                control={control}
                name="question_type"
                label="Tipo de Pergunta *"
                errors={errors}
                options={questionTypeOptions}
                placeholder="Selecione o Tipo de Pergunta"
              />
            </Grid>
            <Grid item xs={12}>
              <Field.Text
                control={control}
                name="name"
                label="Título da Pergunta *"
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Field.Textarea
                control={control}
                name="description"
                label="Texto complementar"
                errors={errors}
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Field.Select
                control={control}
                name="themes"
                label="Tema"
                errors={errors}
                options={themeOptions}
                placeholder="Selecione o Tema"
                isMulti={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Field.Select
                control={control}
                name="tags"
                label="Tag"
                errors={errors}
                options={tagOptions}
                placeholder="Selecione a Tag"
                isMulti={true}
              />
            </Grid>
            <Grid item xs={6}>
              {id ? (
                <Field.Boolean
                  control={control}
                  name="is_active"
                  label="Ativo"
                  errors={errors}
                  color="primary"
                />
              ) : (
                <Field.Hidden
                  control={control}
                  name="is_active"
                  defaultValue={true}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Field.Boolean
                control={control}
                name="has_evidence"
                label="Permitir o envio de evidência pelo usuário?"
                errors={errors}
                color="primary"
              />
            </Grid>
          </Grid>
        </Paper>
        {hasOfferedAnswer ? (
          <Grid item xs={12}>
            <Grid container spacing={1} item xs={12}>
              <Grid item xs={8}>
                <Headers.Grid>Opções de respostas</Headers.Grid>
              </Grid>
              <Grid
                container
                item
                xs={4}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Field.Button
                  label="Nova Opção"
                  color="primary"
                  onClick={handleOfferedAnswersAppend}
                  startIcon={<MdAdd />}
                />
              </Grid>
            </Grid>
            <AnswersList
              control={control}
              offeredAnswersFields={offeredAnswersFields}
              classes={classes}
              errors={errors}
              handleOfferedAnswersRemove={handleOfferedAnswersRemove}
              handleShowSolutionModal={handleShowSolutionModal}
              handleShowPainModal={handleShowPainModal}
            />
          </Grid>
        ) : null}
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Field.Button
              label="Voltar"
              onClick={handleButtonBack}
              startIcon={<MdUndo />}
            />
            <Field.Button
              type="submit"
              label="Salvar"
              color="primary"
              startIcon={<MdSave />}
            />
          </Grid>
          {id ? (
            <Grid container item xs={6} direction="row" justifyContent="flex-end">
              <Field.Button
                label="Nova Pergunta"
                onClick={handleButtonNew}
                color="primary"
                startIcon={<MdAdd />}
              />
              <Field.Button
                type="button"
                label="Clonar Pergunta"
                onClick={handleButtonClone}
                startIcon={<MdContentCopy />}
              />
            </Grid>
          ) : null}
        </Grid>
      </Form>

      {showSolutionModal && (
        <SolutionModal
          open={showSolutionModal}
          setShowSolutionModal={setShowSolutionModal}
          {...solutionModalProps}
        />
      )}
      {showPainModal && (
        <PainModal
          open={showPainModal}
          setShowPainModal={setShowPainModal}
          {...painModalProps}
        />
      )}
    </>
  );
};

export default Index;
