import React from "react";
import { Link } from "react-router-dom";
import { MdAdd, MdEdit, MdDelete, MdContentCopy, MdSwapVert } from "react-icons/md";
import { IconButton, Link as LinkButton } from "@material-ui/core";

import api from "../../../services/api";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Field from "../../../components/Field";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";

const List = (props) => {
    const { path } = props.match;

    const handleButtonClone = async (uuid) => {
        const result = await api.get(`forms/${uuid}/clone/`);

        alert(`Formulário "${result.data.name}" clonado com sucesso `);

        props.history.push(`/forms/update/${result.data.uuid}`);
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            filterable: true,
            sortable: false,
        },
        { field: "name", headerName: "Nome", flex: 1, filtrable: true },
        {
            field: "is_active",
            headerName: "Ativo",
            type: "boolean",
            sortable: false,
            filtrable: true,
            width: 100,
        },
        {
            field: "modified",
            headerName: "Modificado em",
            type: "date",
            width: 180,
            valueFormatter: (params) =>
                new Intl.DateTimeFormat("default", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                }).format(new Date(params.value)),
        },
        {
            field: "uuid",
            headerName: "Ações",
            filterable: false,
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <>
                    <Link to={`${path}/update/${params.value}`}>
                        <IconButton size="small" color="primary">
                            <MdEdit size={24} />
                        </IconButton>
                    </Link> 
                    <Link to={`${path}/order/${params.value}`}>
                        <IconButton size="small" color="primary">
                        <MdSwapVert size={24}></MdSwapVert>
                        </IconButton>
                    </Link>
                    <Link to={`${path}/delete/${params.value}`}>
                        <IconButton size="small" color="secondary">
                            <MdDelete size={24} />
                        </IconButton>
                    </Link>
                    <LinkButton onClick={() => handleButtonClone(params.value)}>
                        <IconButton size="small" color="default">
                            <MdContentCopy size={24} />
                        </IconButton>
                    </LinkButton>
                </>
            ),
        },
    ];
    return (
        <>
            <Sections.List>
                <Headers.List>Formulários</Headers.List>
                <Link to={`${path}/create`}>
                    <Field.Button
                        label="Novo Formulário"
                        color="primary"
                        startIcon={<MdAdd />}
                    />
                </Link>
            </Sections.List>

            <Article>
                <CustomDataGrid columns={columns} path={"forms/"} />
            </Article>
        </>
    );
};

export default List;
