/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { MdUndo, MdSave, MdEdit, MdDelete, MdAdd } from "react-icons/md";

import api from "../../../services/api";

import schema from "./validation";
import schemaNew from "./validationNew";
import Form from "../../../components/Form";
import Field from "../../../components/Field";
import { useState } from "react";
import { validaCPF } from "./ValidarCPFCNPJ";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const UserForm = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const getSchema = () => {
    if (id == undefined) {
      return schemaNew;
    } else {
      return schema;
    }
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(getSchema()),
  });
  const [userId, setUserId] = useState(id);
  const [userCompanies, setUserCompanies] = useState([]);
  const [externalUserId, setExternalUserId] = useState([]);

  React.useEffect(() => {
    (async () => {
      if (userId) {
        try {
          const result = await api.get(`users/${userId}/`);
          if (
            result.data.external_user !== undefined &&
            result.data.external_user.companies !== undefined &&
            result.data.external_user.companies.length > 0
          ) {
            setUserCompanies(result.data.external_user.companies);
          }
          setExternalUserId(result.data.external_user.uuid);
          setValue("first_name", result.data.first_name);
          setValue("last_name", result.data.last_name);
          setValue("email", result.data.email);
          setValue("document", result.data.document);
          setValue("birth_date", result.data.birth_date);
          // setValue("is_consultant", result.data.is_consultant);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [userId]);
  const document = watch("document");
  React.useEffect(() => {
    checkUser(document);
  }, [document]);
  const checkUser = async (cpf) => {
    if (cpf != undefined) {
      cpf = cpf.replace(/[^0-9]/g, "");
      const document = getValues("document");
      if (cpf.length == 11 && cpf !== document) {
        const result = await api.get(
          `users/0/?filter=username&operator=contains&value=${cpf}`
        );
        if (result.data.count != 0) {
          const people = result.data.data[0];
          if (people.is_consultant == false) {
            setUserId(people.uuid);
          } else {
            alert(
              "Este usuario já está setado como consultor, não é possível cadastrar novamente"
            );
            setValue("document", "");
          }
        }
        setValue("document", cpf);
      }
    }
  };

  const onSubmit = async (data) => {
    if (userId == undefined) {
      data["is_consultant"] = true;
      try {
        const response = await api.post(`/consultants/users/`, data);
        // console.log(response);
        setUserId(response.data.uuid);
        setExternalUserId(response.data.external_user.uuid);
        setValue("password", "");
        setValue("confirm_password", "");
        props.history.push(`/users/consultants/update/${response.data.uuid}`);
      } catch (e) {
        alert("Erro ao salvar");
      }
      // if(response.data.error==false){
      // props.history.push("/users/consultants");
      // }
    } else {
      const response = await api.patch(`users/${userId}/`, data);
      props.history.push("/users/consultants");
    }
  };

  const handleButtonBack = () => {
    props.history.push("/users/consultants");
  };
  const handleButtonAddCompany = () => {
    props.history.push(
      `/users/consultants/${userId}/${externalUserId}/company`
    );
  };
  const handleButtonEditCompany = (companyId) => {
    props.history.push(
      `/users/consultants/${userId}/${externalUserId}/company/${companyId}`
    );
  };
  const handleButtonDeleteCompany = (companyId) => {
    props.history.push(
      `/users/consultants/${userId}/${externalUserId}/company/delete/${companyId}`
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={6}>
            <Field.TextMask
              mask={"999.999.999-99"}
              control={control}
              name="document"
              label="CPF"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="first_name"
              label="Nome"
              autoComplete="new"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="last_name"
              label="Sobrenome"
              autoComplete="new"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              type="email"
              name="email"
              label="Email"
              autoComplete="off"
              errors={errors}
            />
          </Grid>

          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="birth_date"
              type="date"
              label="Data de Nascimento*"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              type="password"
              name="password"
              autoComplete="new-password"
              label="Senha"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              type="password"
              name="confirm_password"
              label="Repita a Senha"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Boolean
              control={control}
              name="is_consultant"
              onChange={() => true}
              defaultValue={true}
              label="É Consultor"
              errors={errors}
              color="primary"
            />
          </Grid>
        </Grid>
      </Paper>
      {userId !== undefined && (
        <React.Fragment>
          <Typography variant="h6" color="primary">
            Empresas Vinculadas
          </Typography>
          <Field.Button
            type="button"
            label="Adicionar Nova"
            color="primary"
            onClick={handleButtonAddCompany}
            startIcon={<MdAdd />}
          />
          {userCompanies?.map((x, i) => {
            return (
              <Paper className={classes.paper} key={i}>
                <Grid
                  container
                  item
                  spacing={3}
                  xs={12}
                  className={classes.grid}
                >
                  <Grid item xs={3}>
                    <Typography variant="h6" color="primary">
                      {x.name}
                    </Typography>
                    <Typography variant="body2">{x.document}</Typography>
                    <br />
                    <Typography variant="body2">{x.email}</Typography>
                    <br />
                    <Typography variant="body2">{x.phone}</Typography>
                    <br />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      Segmento: {x.segmento}
                    </Typography>
                    <br />
                    <Typography variant="body2">
                      Atv. Economica: {x.cod_atv_economica} {x.atv_economica}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field.Button
                      type="button"
                      label="Editar"
                      color="primary"
                      onClick={() => handleButtonEditCompany(x.uuid)}
                      startIcon={<MdEdit />}
                    />
                    <Field.Button
                      type="button"
                      label="Excluir"
                      color="secondary"
                      onClick={() => handleButtonDeleteCompany(x.uuid)}
                      startIcon={<MdDelete />}
                    />
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </React.Fragment>
      )}
      <Grid item xs={12}>
        <Field.Button
          label="Voltar"
          onClick={handleButtonBack}
          startIcon={<MdUndo />}
        />
        <Field.Button
          type="submit"
          label="Salvar"
          color="primary"
          startIcon={<MdSave />}
        />
      </Grid>
    </Form>
  );
};

export default UserForm;
