/* eslint-disable no-unused-vars */
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import api from "../../../services/api";
import Questions from "./questions";
import { MdUndo } from "react-icons/md";
import Field from "../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paperFinancas: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((val, ind) => {
    result[ind].order = ind;
  });
  return result;
};

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [form, setForm] = React.useState([]);
  const [themes, setThemes] = React.useState([]);
  const history = useHistory();
  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const resultForm = await api.get(`forms/${id}/order/`);
          setForm(resultForm.data);
          setThemes(resultForm.data.themes);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      setThemes(reorder(themes, source.index, destination.index));
      saveOrder();
    }
  };

  const updateQuestions = (questions, themeIndex) => {
    let themesVar = themes;
    themesVar[themeIndex].questions = questions;
    setThemes(themesVar);
    saveOrder();
  };

  const saveOrder = async () => {
    let data = form;
    data.themes = themes;
    // console.log(form);
    let response = await api.patch(`forms/${id}/order/`, data);
    // console.log(response);
  };

  const handleButtonBack = () => {
    history.push("/forms");
  };
  if (form.themes != undefined) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <h3>{form.name}</h3>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableThemes">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {provided.placeholder}
                  {themes.map(({ name, order, uuid, questions }, index) => (
                    <Grid key={uuid} item xs={12}>
                      <Draggable key={uuid} draggableId={uuid} index={index}>
                        {(provided, snapshot) => (
                          <Paper
                            className={classes.paperFinancas}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <h5>{name}</h5>
                            <Questions
                              questions={questions}
                              themeIndex={index}
                              updateQuestions={updateQuestions}
                            ></Questions>
                          </Paper>
                        )}
                      </Draggable>
                    </Grid>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
};
export default Index;
