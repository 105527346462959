import React from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Box } from "@material-ui/core";

import api from "../../../../../services/api";

import Field from "../../../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = () => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [applicationTypeOptions, setApplicationTypeOptions] = React.useState(
    []
  );
  const [formTypeOptions, setFormTypeOptions] = React.useState([]);
  const [interviewedTypeOptions, setInterviewedTypeOptions] = React.useState(
    []
  );

  React.useEffect(() => {
    (async () => {
      try {
        appendApplicationTypeOptions();
        appendFormTypeOptions();
        appendInterviewedTypeOptions();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const resultForm = await api.get(`forms/${id}/`);

          setValue("name", resultForm.data.name);
          // setValue("description", resultForm.data.description);
          setValue("form_type", appendFormType(resultForm.data.form_type));
          setValue(
            "interviewed_type",
            appendInterviewedType(resultForm.data.interviewed_type)
          );
          setValue("value", parseInt(resultForm.data.value));
          setValue(
            "application_type",
            appendApplicationType(resultForm.data.application_type)
          );
          // setValue("is_readonly", resultForm.data.is_readonly);
          setValue("only_company", resultForm.data.only_company);
          setValue("is_active", resultForm.data.is_active);

          setValue("qty_recommendations", resultForm.data.qty_recommendations);
          setValue("min_products", resultForm.data.min_products);
          setValue("max_products", resultForm.data.max_products);
          setValue("validity", resultForm.data.validity);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const appendApplicationType = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const appendFormType = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const appendInterviewedType = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const appendApplicationTypeOptions = async () => {
    const result = await api.get("applications/types/");

    let options = [];

    result.data.data.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setApplicationTypeOptions(options);
  };

  const appendFormTypeOptions = async () => {
    const result = await api.get("forms/types/");
    let options = [];

    result.data.data.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setFormTypeOptions(options);
  };

  const appendInterviewedTypeOptions = async () => {
    const result = await api.get("interviewees/types/");
    let options = [];

    result.data.data.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setInterviewedTypeOptions(options);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container item spacing={3} xs={12} className={classes.grid}>
        <Grid item xs={12}>
          <Field.Select
            control={control}
            name="application_type"
            label="Tipo de Aplicação *"
            errors={errors}
            options={applicationTypeOptions}
            placeholder="Selecione a Aplicação"
          />
        </Grid>
        <Grid item xs={12}>
          <Field.Text
            control={control}
            name="name"
            label="Nome *"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Field.Select
            control={control}
            name="form_type"
            label="Tipo de Formulário *"
            errors={errors}
            options={formTypeOptions}
            placeholder="Selecione o Tipo de Formulário"
          />
        </Grid>
        <Grid item xs={12}>
          <Field.Select
            control={control}
            name="interviewed_type"
            label="Tipo de Entrevistado *"
            errors={errors}
            options={interviewedTypeOptions}
            placeholder="Selecione o Tipo de Entrevistado"
          />
        </Grid>
        <Grid item xs={3}>
          <Field.NumberMask
            control={control}
            name="value"
            label="Valor de Pontos do Formulário *"
            errors={errors}
            onChange={(value) => value}
            mask={{
              allowDecimal: false,
              suffix: "",
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 4,
            }}
          />
        </Grid>

        {/* <Grid item xs={12}>
                    <Field.Textarea
                        control={control}
                        name="description"
                        label="Descrição"
                        errors={errors}
                        rows={4}
                    />
                </Grid> */}
        {/* <Grid item xs={12}>
                    <Field.Textarea
                        control={control}
                        name="confirmation_message"
                        label="Mensagem de Confirmação"
                        errors={errors}
                        rows={4}
                    />
                </Grid> */}
        <Grid item xs={12}>
          <Field.Boolean
            control={control}
            name="is_public"
            label="Permitir o preenchimento sem necessidade de realizar login na ferramenta"
            errors={errors}
            color="primary"
          />
          <Box fontStyle="italic">
            Marque essa opção caso essa avaliação possa ser respondida de forma
            anônima sem a necessidade de fazer login na ferramenta
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Field.Boolean
            control={control}
            name="only_company"
            label="Somente Empresa"
            errors={errors}
            color="primary"
          />
          <Box fontStyle="italic">
            Marque essa opção caso essa avaliação possa ser respondida por uma
            pessoa Juridica (vínculo com empresa)
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Field.Boolean
            control={control}
            name="is_active"
            label="Ativo"
            errors={errors}
            color="primary"
          />
          <Box fontStyle="italic">Para ativar ou desativar o formulário</Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Index;
