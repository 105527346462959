/* eslint-disable no-undef */
import axios from "axios";

// eslint-disable-next-line no-undef
const { REACT_APP_API_BASE_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `JWT ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
// try{
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
    // const {
    //   config,
    //   response: { status },
    // } = error;
    // const originalRequest = config;

    // if (status === 401) {
    //   const refresh_token = localStorage.getItem("refresh_token");
    //   const {
    //     data: { access, refresh },
    //   } = await api.post("/token/refresh/", {
    //     refresh: refresh_token,
    //   });
    //   localStorage.setItem("access_token", access);
    //   localStorage.setItem("refresh_token", refresh);

    //   api.defaults.headers.Authorization = `JWT ${access}`;
    //   originalRequest.headers.Authorization = `JWT ${access}`;

    //   return api(originalRequest);
    // } else {
    //   if (status === 403) {
    //     localStorage.removeItem("access_token");
    //     localStorage.removeItem("refresh_token");

    //     api.defaults.headers.Authorization = undefined;
    //     originalRequest.headers.Authorization = undefined;

    //     return (window.location.href = "/");
    //   }
    // }

//     return Promise.reject(error);
//   }
// );
// }catch (error) {
//   console.log(error);
// }

export default api;
