import * as Yup from "yup";

export default Yup.object().shape({
    first_name: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    last_name: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    email: Yup.string()
        .email("Informe um email válido.")
        .required("Campo obrigatório."),
});
