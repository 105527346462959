import React, { useRef } from "react";
import { useController } from "react-hook-form";

import EditorVars from "./styles";
import vars from "./plugin_vars";
// import SunEditor from "suneditor/src/lib/core";

const Input = ({ control, name, defaultValue, variables, ...defaultProps }) => {
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    variables,
    // rules: { required: true },
    defaultValue: defaultValue,
  });
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
    let plugins = sunEditor.core.plugins;
    plugins["variaveis"] = vars(variables);
    let buttonList = [
      ["variaveis"],
      ["undo", "redo"],
      [
        ":p-More Paragraph-default.more_paragraph",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
      ],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor", "textStyle"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "lineHeight"],
      ["-right", "image", "video", "audio", "link"],
    ];
    // buttonList = buttonList.push(["variaveis"]);
    sunEditor.setOptions({
      minHeight: "300px",
      katex: "window.katex",
      plugins: plugins,
      buttonList: buttonList,
    });
  };

  return (
    <EditorVars
      {...inputProps}
      setContents={defaultValue}
      getSunEditorInstance={getSunEditorInstance}
      inputRef={ref}
      {...defaultProps}
    />
  );
};

export default Input;
