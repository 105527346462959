/* eslint-disable no-unused-vars */
import React from "react";
// import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paperFinancas: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((val, ind)=>{
    result[ind].order = ind;
  });
  return result;
};
const Questions = (props) => {
  const classes = useStyles();
  const [questions, setQuestions] = React.useState(props.questions);
  const update = props.updateQuestions;
  const themeIndex = props.themeIndex;
  React.useEffect(() => {
    (async () => {
      try {
        // console.log(props);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      let qsReorder = reorder(questions, source.index, destination.index);
      setQuestions(qsReorder);
      update(qsReorder,themeIndex);
    }
  };
  return (
    <div>
      <h3>Perguntas</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableQuestions">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {provided.placeholder}
              {questions.map(({ name, uuid }, ind) => (
                <Draggable key={uuid} draggableId={uuid} index={ind}>
                  {(provided, snapshot) => (
                    <Paper
                      className={classes.paper}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {name}
                    </Paper>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default Questions;
