import React from "react";
import { Router } from "react-router-dom";

import {AuthProvider } from "./contexts/AuthContext";
import MainLayout from "./layouts/Main";
import Routes from "./routes";
import history from "./history";

export default function App() {

  return (
    <AuthProvider >
      <MainLayout>
        <Router history={history}>
          <Routes />
        </Router>
      </MainLayout>
    </AuthProvider>
  );
}
