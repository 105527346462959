import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { MdUndo, MdSave } from "react-icons/md";
import api from "../../../services/api";

import schema from "./validation";
import Form from "../../../components/Form";
import Field from "../../../components/Field";
import EditorVars from "../../../components/Field/EditorVars";

// import DragDropEditor from "../../../components/Field/DragDropEditor";
// import ProductList from "../../../components/Field/DragDropEditor/productlist";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
  editorBox: {
    zIndex: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [content, setContent] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [mailModelVariables, setMailModelVariables] = React.useState();

  // const [ckeditor, setCkeditor] = React.useState();

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`mail_model/${id}/`);
          const resultVars = await api.get(`mail_model_variable/`);
          let vars = [];
          result.data.mail_model_variables.forEach((varId) => {
            let res = resultVars.data.data.find((variable) => {
              return variable.id == varId;
            });
            vars.push(res);
          });
          setMailModelVariables(vars);
          setValue("title", result.data.title);
          setSlug(result.data.slug);
          setValue("subject", result.data.subject);
          setContent(result.data.content);
          setValue("is_active", result.data.is_active);
        } catch (e) {
          console.error(e);
        }
      } else {
        setMailModelVariables([]);
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      await api.patch(`mail_model/${id}/`, data);
    } else {
      await api.post(`mail_model/`, data);
    }

    props.history.push("/mail_models");
  };

  const handleButtonBack = () => {
    props.history.push("/mail_models");
  };
  // const handleChangeTitle = (event) => {
    // if (id == undefined) {
    //   setValue(event.target.name, event.target.value);
    // }
  // };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="title"
              label="Titulo *"
              errors={errors}
              // onChange={handleChangeTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" component="div">
              Identificação: {slug}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="subject"
              label="Assunto *"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} className={classes.editorBox}>
            {mailModelVariables !== undefined && (
              <EditorVars
                control={control}
                name="content"
                label="Conteúdo do E-Mail *"
                errors={errors}
                defaultValue={content}
                variables={mailModelVariables}
              />
            )}
            {id ? (
              <Grid item xs={12}>
                <Field.Boolean
                  control={control}
                  name="is_active"
                  label="Ativo"
                  errors={errors}
                  color="primary"
                />
              </Grid>
            ) : (
              <Field.Hidden
                control={control}
                name="is_active"
                defaultValue={true}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            type="submit"
            label="Salvar"
            color="primary"
            startIcon={<MdSave />}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default Index;
