import {
    Button,
    CardMedia,
    Container,
    TextField,
    Typography,
} from "@material-ui/core";
import styled from "styled-components";

export const ContainerStyled = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const FormStyled = styled.form`
    width: 300px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextFieldStyled = styled(TextField)`
    flex: 1;
    height: 46px;
    margin-bottom: 15px;
    font-size: 15px;
    width: 100%;
    &::placeholder {
        color: #999;
    }
`;

export const ButtonStyled = styled(Button)`
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
`;

export const CardMediaStyled = styled(CardMedia)`
    width: 140px;
    height: 90px;
    margin: 10px 0 20px;
`;

export const TypographyStyled = styled(Typography)`
    margin-bottom: 30px;
`;
