import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { MdUndo, MdSave } from "react-icons/md";

import api from "../../../services/api";

import schema from "./validation";
import Form from "../../../components/Form";
import Field from "../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const UserForm = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isStaff, setIsStaff] = useState(false);
  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`users/${id}/`);

          setValue("first_name", result.data.first_name);
          setValue("last_name", result.data.last_name);
          setValue("email", result.data.email);
          setValue("is_consultant", result.data.is_consultant);
          setIsStaff(result.data.is_staff);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    await api.patch(`users/${id}/`, data);

    props.history.push("/users");
  };

  const handleButtonBack = () => {
    props.history.push("/users");
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="first_name"
              label="Nome"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="last_name"
              label="Sobrenome"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              type="email"
              name="email"
              label="Email"
              errors={errors}
            />
          </Grid>
          {isStaff == false && 1==2 && (
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name="is_consultant"
                label="É Consultor"
                errors={errors}
                color="primary"
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Field.Button
          label="Voltar"
          onClick={handleButtonBack}
          startIcon={<MdUndo />}
        />
        <Field.Button
          type="submit"
          label="Salvar"
          color="primary"
          startIcon={<MdSave />}
        />
      </Grid>
    </Form>
  );
};

export default UserForm;
