import React from "react";
import { useParams } from "react-router-dom";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";

import Theme from "../../../domain/Theme";

const Form = (props) => {
  const { id } = useParams();
  return (
    <>
      <Sections.Form>
        <Headers.Form>{id ? "Editar" : "Adicionar"} Aplicação</Headers.Form>
      </Sections.Form>

      <Article>
        <Theme {...props}></Theme>
      </Article>
    </>
  );
};

export default Form;
