import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./components/List";
import Form from "./components/Form";
import Delete from "./components/Delete";

const Index = ({ match }) => {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={List} />
            <Route exact path={`${path}/create/`} component={Form} />
            <Route exact path={`${path}/update/:id`} component={Form} />
            <Route exact path={`${path}/delete/:id`} component={Delete} />
        </Switch>
    );
};

export default Index;
