import React from "react";
import { useParams } from "react-router-dom";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import UserConsultantForm from "../../../domain/User/FormConsultant";

const Form = (props) => {
    const { id } = useParams();

    return (
        <>
            <Sections.Form>
                <Headers.Form>
                    {id ? "Editar" : "Adicionar"} Usuário
                </Headers.Form>
            </Sections.Form>

            <Article>
                <UserConsultantForm {...props} />
            </Article>
        </>
    );
};

export default Form;
