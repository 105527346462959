import { useState, useEffect } from "react";

import api from "../../services/api";
import history from "../../history";

export default function useAuth() {
    const emptyUser = { name: "", email: "" };
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(emptyUser);
    const [error, setError] = useState('');

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            api.defaults.headers.Authorization = `JWT ${token}`;
            setAuthenticated(true);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (authenticated) {
                    const resultUser = await api.get("user");

                    setUser(resultUser.data);
                } else {
                    setUser(emptyUser);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [authenticated]);

    function handleError(value) {
        setError(value);
    }

    async function handleLogin(username, password) {
        try {
            const {
                data: { access, refresh },
            } = await api.post("/token/obtain/", {
                username,
                password,
            });

            localStorage.setItem("access_token", access);
            localStorage.setItem("refresh_token", refresh);
            api.defaults.headers.Authorization = `JWT ${access}`;
            setAuthenticated(true);
            history.push("/dashboard");
        } catch (e) {
            const {
                data: { detail },
            } = e.response;
        
            handleError(detail);
        }
    }

    function handleLogout() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        api.defaults.headers.Authorization = undefined;
        setAuthenticated(false);
        history.push("/login");
    }

    return { authenticated, loading, user, handleLogin, handleLogout, error, handleError };
}
