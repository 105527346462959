import * as Yup from "yup";

export default Yup.object().shape({
    title: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    term: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    is_active: Yup.boolean(),
});
