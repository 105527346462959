/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Box } from "@material-ui/core";
import { MdAdd, MdUndo, MdSave } from "react-icons/md";

import api from "../../../services/api";

import schema from "./validation";
import Form from "../../../components/Form";
import Field from "../../../components/Field";
import moment from "moment";
import { CardMediaStyled } from "../../../pages/Login/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [formOptions, setFormOptions] = React.useState([]);
  const [image, setImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [showMakeConsult, setShowMakeConsult] = React.useState(false);
  const [makeConsultMessage, setMakeConsultMessage] = React.useState("");
  const [description, setDescription] = React.useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  React.useEffect(() => {
    (async () => {
      try {
        listForms();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`campaigns/${id}/`);
          if (result.data.image != null) {
            const { REACT_APP_MEDIA_BASE_URL } = process.env;
            setImageUrl(REACT_APP_MEDIA_BASE_URL + "/" + result.data.image);
          }
          console.log(result.data);
          setValue("name", result.data.name);
          setValue("slug", result.data.slug);
          setValue("form_id", appendForm(result.data.form));
          setValue("spotlight", result.data.spotlight);
          // setValue("description", result.data.description);
          setDescription(result.data.description);
          setValue("date_start", result.data.date_start);
          setValue("date_end", result.data.date_end);
          setValue("is_internal", result.data.is_internal);
          setValue(
            "is_selectable_by_consultant",
            result.data.is_selectable_by_consultant
          );
          setValue(
            "is_selectable_by_self_service",
            result.data.is_selectable_by_self_service
          );
          // setValue("use_image", result.data.use_image);

          setValue("image", "");
          setValue("is_active", result.data.is_active);
          setValue("make_consult", result.data.make_consult);
          setShowMakeConsult(result.data.make_consult);
          // setValue("make_consult_message", result.data.make_consult_message);
          setMakeConsultMessage(result.data.make_consult_message);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    let formData = new FormData();
    if (data.form_id.value != null) {
      if (image != null) {
        formData.append("image", image);
      }
      formData.append("form_uuid", data.form_id.value);
      formData.append("name", data.name);
      formData.append("slug", data.slug);
      formData.append("spotlight", data.spotlight);
      formData.append("description", data.description);
      formData.append(
        "date_start",
        moment(data.date_start).locale("en").format("YYYY-MM-DD")
      );
      let date_end =
        data.date_end != null
          ? moment(data.date_end).locale("en").format("YYYY-MM-DD")
          : "";
      formData.append("date_end", date_end);
      formData.append(
        "is_selectable_by_consultant",
        data.is_selectable_by_consultant
      );
      formData.append(
        "is_selectable_by_self_service",
        data.is_selectable_by_self_service
      );
      formData.append("is_active", data.is_active);
      formData.append("make_consult", data.make_consult);
      formData.append("make_consult_message", data.make_consult_message);

      if (id) {
        await api.patch(`campaigns/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await api.post(`campaigns/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      props.history.push("/campaigns");
    }
  };

  const handleButtonBack = () => {
    props.history.push("/campaigns");
  };

  const handleButtonNew = () => {
    props.history.push("/campaigns/create");
  };

  const appendForm = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const listForms = async () => {
    const result = await api.get(`forms/`);
    let options = [];
    result.data.data.map((value) => {
      options.push({
        value: value?.uuid,
        label: value?.name,
      });
    });
    setFormOptions(options);
  };
  const string_to_slug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };
  
  const handleChangeSlug = (e) => {
    let value = e.target.value;
    setValue(e.target.name, string_to_slug(value));
    return value;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          {!id ? (
            <Grid item xs={12}>
              <Field.Select
                control={control}
                name="form_id"
                label="Formulário *"
                errors={errors}
                options={formOptions}
                placeholder="Selecione o Tipo de Formulário"
                readOnly={true}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="name"
              label="Nome *"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="slug"
              label="SLUG *"
              onChange={(value) => handleChangeSlug(value)}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="spotlight"
              label="Chamada"
              errors={errors}
            />
            <Box fontStyle="italic">
              Texto que aparecerá na listagem de programas
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Field.Editor
              control={control}
              name="description"
              label="Descrição"
              errors={errors}
              rows={4}
              defaultValue={description}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="date_start"
              type="date"
              label="Data Inicio *"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="date_end"
              type="date"
              label="Data Final"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.File
              control={control}
              name="image"
              label="Imagem"
              onBlur={(e) => {
                if (e.target.files.length > 0) {
                  setImage(e.target.files[0]);
                }
                return e;
              }}
              errors={errors}
            />
          </Grid>
          {imageUrl != null ? (
            <Grid item xs={6}>
              <CardMediaStyled image={imageUrl} title="Imagem" />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Field.Boolean
              control={control}
              name="make_consult"
              label="Ira gerar consulta com hora marcada?"
              onChange={(e) => {
                setValue("make_consult", e.target.checked);
                setShowMakeConsult(e.target.checked);
                if (e.target.checked == false) {
                  setMakeConsultMessage("");
                  setValue("make_consult_message", "");
                }
              }}
              errors={errors}
              color="primary"
            />
          </Grid>
          {showMakeConsult ? (
            <Grid item xs={12}>
              <Field.Editor
                control={control}
                name="make_consult_message"
                label="Texto final para gerar a consulta"
                errors={errors}
                color="primary"
                defaultValue={makeConsultMessage}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <Field.Boolean
              control={control}
              name="is_selectable_by_consultant"
              label="Deseja que esse programa seja selecionável pelo consultor?"
              errors={errors}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Boolean
              control={control}
              name="is_selectable_by_self_service"
              label="Deseja que esse programa seja selecionável pelo Site?"
              errors={errors}
              color="primary"
            />
          </Grid>

          {id ? (
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name="is_active"
                label="Ativo"
                errors={errors}
                color="primary"
              />
            </Grid>
          ) : (
            <Field.Hidden
              control={control}
              name="is_active"
              defaultValue={true}
            />
          )}
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            type="submit"
            label="Salvar"
            color="primary"
            startIcon={<MdSave />}
          />
        </Grid>
        {id ? (
          <Grid container item xs={6} direction="row">
            <Field.Button
              label="Nova Campanha"
              onClick={handleButtonNew}
              color="primary"
              startIcon={<MdAdd />}
            />
          </Grid>
        ) : null}
      </Grid>
    </Form>
  );
};

export default Index;
