import * as Yup from "yup";
import { validaCNPJ } from "./ValidarCPFCNPJ";

export default Yup.object().shape({
  name: Yup.string()
    .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    .required("Campo obrigatório."),
  email: Yup.string()
    .email("Informe um email válido.")
    .required("Campo obrigatório."),
  document: Yup.string()
    .required("CNPJ é obrigatório")
    .test("test-invalid-cnpj", "CNPJ inválido", (cnpj) => validaCNPJ(cnpj))
    .transform((value) => value.replace(/[^\d]/g, ""))
    .min(14, "O CNPJ precisa ter 14 caracteres"),
  phone: Yup.string().required("Campo obrigatório."),
});
