import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tabs, Tab } from "@material-ui/core";
// import { MdAdd, MdUndo, MdSave, MdContentCopy, MdClose } from "react-icons/md";

import api from "../../../../../services/api";

// import Field from "../../../../../components/Field";
import Theme from "./components/Theme";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{
                display: value !== index ? "none" : "block",
                width: "100%",
            }}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        width: "100%",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flexGrow: 1,
        display: "flex",
        height: "calc(100vh - 22rem)",
    },
    grid: {
        margin: 0,
    },
    tabs: {
        width: 200,
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const Index = (props) => {
    const classes = useStyles();
    const { id } = useParams();
    const {
        // eslint-disable-next-line no-unused-vars
        formState: { errors },
        // setValue,
    } = useFormContext();
    const [tabActive, setTabActive] = React.useState(0);
    const [tabList, setTabList] = React.useState([]);
    const [valueAddTab, setValueAddTab] = React.useState(0);
    const {
        themeSelected,
        setThemeSelected,
        setShowThemeModal,
        themeModalProps,
        setThemeModalProps,
    } = props;

    const handleTabChange = (e, newValue) => {
        setTabActive(newValue);
    };

    React.useEffect(() => {
        (async () => {
            try {
                const resultPages = await api.get(`forms/${id}/pages/`);

                appendTabList(resultPages.data.data);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    const appendTabList = (params) => {
        let options = [];

        params.map((value) =>
            options.push({
                key: value.theme.uuid,
                id: tabList.length,
                pk: value.theme.id,
                label: value.theme.name,
            })
        );

        setTabList(options);
        setValueAddTab(options.length);
    };

    React.useEffect(() => {
        (async () => {
            if (themeSelected) {
                try {
                    const index = tabList.length;
                    const lastId = index - 1;
                    const newTabList = {
                        key: themeSelected.key,
                        id: lastId + 1,
                        pk: themeSelected.pk,
                        label: themeSelected.label,
                    };

                    setTabList([
                        ...tabList,
                        {
                            ...newTabList,
                        },
                    ]);
                    setTabActive(lastId + 1);
                    setValueAddTab(index + 1);

                    const themes = themeModalProps.themes || [];
                    setThemeModalProps({
                        themes: [...themes, themeSelected.key],
                    });

                    setThemeSelected(null);
                } catch (e) {
                    console.error(e);
                }
            }
        })();
    }, [themeSelected]);

    const handleAddTab = () => {
        setShowThemeModal(true);
    };

    return (
        <Paper className={classes.paper}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabActive}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                className={classes.tabs}
            >
                {tabList.map((tab) => (
                    <Tab
                        key={tab.key}
                        label={tab.label}
                        {...a11yProps(tab.id)}
                    />
                ))}
                <Tab
                    label="Adicionar Pergunta"
                    value={valueAddTab}
                    disableFocusRipple={true}
                    disableRipple={true}
                    onClick={handleAddTab}
                />
            </Tabs>
            {tabList.map((tab, index) => (
                <TabPanel
                    key={tab.key}
                    value={tabActive}
                    index={index}
                    tab={tab}
                >
                    <Theme
                        theme={tab}
                        themeIndex={index}
                        themActive={tabActive === index}
                        {...props}
                    />
                </TabPanel>
            ))}
        </Paper>
    );
};

export default Index;
