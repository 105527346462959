import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./components/List";
import Form from "./components/Form";
import Delete from "./components/Delete";
import RelatedVariables from "./components/RelatedVariables";

const Index = ({ match }) => {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={List} />
            <Route exact path={`${path}/create`} component={Form} />
            <Route exact path={`${path}/update/:id`} component={Form} />
            <Route exact path={`${path}/delete/:id`} component={Delete} />
            <Route exact path={`${path}/:id/related_vars`} component={RelatedVariables} />
        </Switch>
    );
};

export default Index;
