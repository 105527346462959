/* eslint-disable no-unused-vars */
import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Chip
} from "@material-ui/core";
// import { MdClose, MdRadioButtonUnchecked, MdSave } from "react-icons/md";
import { MdClose } from "react-icons/md";
import CustomDataGrid from "../../../../../../../components/DataGrid";

import Field from "../../../../../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    maxHeight: "100vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: "640px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const { open, setOpen, setSelected, theme } = props;
  const [reload, setReload] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelected = (params) => {
    setSelected({
      key: params.uuid,
      pk: params.id,
      label: params.name,
      theme: theme.key,
    });

    handleClose();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      filterable: true,
      sortable: true,
    },
    { field: "name", headerName: "Pergunta", flex: 1 },
    {
      field: "tags",
      headerName: "Tags",
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.tags.map(({ name }, ind) => (
              <Chip key={ind} variant="outlined" size="small" label={name} />
            ))}
          </>
        );
      },
    },
    { field: "user.name", headerName: "Criador", flex: 1, renderCell: (params) => {
        return (<>{params.row.user.first_name}</>)
    }},
    {
      field: "uuid",
      headerName: "Ações",
      // filterable: false,
      // sortable: false,
      width: 120,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Field.Button
          type="button"
          label="Selecionar"
          onClick={() => handleSelected(params.row)}
          color="primary"
        />
      ),
    },
  ];

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        disableEnforceFocus
        open={open}
        fullWidth
        maxWidth="lg"
        className={classes.root}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {`${theme.label}: Selecione uma Pergunta`}
        </DialogTitle>

        <DialogContent dividers>
          <CustomDataGrid
            columns={columns}
            path={`themes/${theme.key}/forms/${id}/questions/`}
            reload={reload}
            setReload={setReload}
            modal={true}
          />
        </DialogContent>

        <DialogActions>
          <Field.Button
            label="Fechar"
            onClick={handleClose}
            color="default"
            startIcon={<MdClose />}
            autoFocus
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Index;
