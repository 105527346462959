import React from "react";
import { useController } from "react-hook-form";

import Fields from "./styles";

const Input = ({ control, name, defaultValue, mask, ...defaultProps }) => {
    const {
        field: { ref, ...inputProps },
        // fieldState: { invalid, isTouched, isDirty },
        // formState: { touchedFields, dirtyFields },
    } = useController({
        name,
        control,
        // rules: { required: true },
        defaultValue: defaultValue,
    });

    return (
        <Fields.Mask mask={mask} {...inputProps}>
            {(props) => (
                <Fields.Text {...props} inputRef={ref} {...defaultProps} />
            )}
        </Fields.Mask>
    );
};

export default Input;
