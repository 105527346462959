import React from "react";
import { useController } from "react-hook-form";

import TextField from "./styles";

const Input = ({ control, name, defaultValue, ...defaultProps }) => {
    const {
        field: { ref, ...inputProps },
        // fieldState: { invalid, isTouched, isDirty },
        // formState: { touchedFields, dirtyFields },
    } = useController({
        name,
        control,
        // rules: { required: true },
        defaultValue: defaultValue,
        
    });

    return <TextField {...inputProps} inputRef={ref}  {...defaultProps} />;
};

export default Input;
