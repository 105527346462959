import React from "react";
import { useParams } from "react-router";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Typography,
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    IconButton,
} from "@material-ui/core";
import { MdClose } from "react-icons/md";
import CustomDataGrid from "../../../../../../../components/DataGrid";

import Field from "../../../../../../../components/Field";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        maxHeight: "100vh",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        width: "100%",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <MdClose />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: "640px",
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const Index = (props) => {
    const classes = useStyles();
    const { id } = useParams();
    const { open, setOpen, setSelected, themes } = props;
    const [reload, setReload] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelected = (params) => {
        setSelected({
            key: params.uuid,
            pk: params.id,
            label: params.name,
        });

        handleClose();
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
            filterable: false,
            sortable: true,
        },
        { field: "name", headerName: "Nome", flex: 1 },
        { field: "color", headerName: "Cor", flex: 1,renderCell:(params)=>{
            const spanStyle = {
                height:"25px",
                width: "25px",
                float: "left",
                marginTop: "12%",
                marginRight: "5px",
                border: "#CCC 1px solid",
                background: params.formattedValue
            };
            return (<div><div style={spanStyle}>&nbsp;</div>{params.formattedValue}</div>)} 
        },
        {
            field: "uuid",
            headerName: "Ações",
            filterable: false,
            sortable: false,
            width: 120,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <Field.Button
                    type="button"
                    label="Selecionar"
                    onClick={() => handleSelected(params.row)}
                    color="primary"
                />
            ),
        },
    ];

    return (
        <div>
            <Dialog
                onClose={handleClose}
                disableEnforceFocus 
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="lg"
                className={classes.root}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Selecione um Pergunta/Tema
                </DialogTitle>

                <DialogContent dividers>
                    <CustomDataGrid
                        columns={columns}
                        path={`forms/${id}/themes/`}
                        pathParams={{
                            themes: themes,
                        }}
                        reload={reload}
                        setReload={setReload}
                        modal={true}
                    />
                </DialogContent>

                <DialogActions>
                    <Field.Button
                        label="Fechar"
                        onClick={handleClose}
                        color="default"
                        startIcon={<MdClose />}
                        autoFocus
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Index;
