import React from "react";
import { Link } from "react-router-dom";


export default function Page404() {
    return (
        <>
            <div>
                <h1>Página não encontrada</h1>
                <p>Desculpe, não há nada para ver aqui.</p>
                <p>
                    <Link to="/dashboard">Voltar para o início</Link>
                </p>
            </div>
        </>
    );
}
