import React from "react";
import { useParams } from "react-router-dom";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import CompanyForm from "../../../domain/User/FormConsultant/companyForm";

const Form = (props) => {
  const { id } = useParams();

  return (
    <>
      <Sections.Form>
        <Headers.Form>{id ? "Editar" : "Adicionar"} Empresa</Headers.Form>
      </Sections.Form>

      <Article>
        <CompanyForm {...props} />
      </Article>
    </>
  );
};

export default Form;
