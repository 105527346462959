/* eslint-disable no-unused-vars */
import React from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";

import api from "../../../../../services/api";

import Field from "../../../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
  editorBox: {
    zIndex: 0,
  },
}));
const devolutiveModels = [
  {
    name: "Sem Devolutiva",
    slug: "sem_devolutiva",
  },
  {
    name: "Devolutiva Empresas",
    slug: "devolutiva_empresas",
  },
];

const Index = () => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [modelOptions, setModelOptions] = React.useState([]);
  const [showFields, setShowFields] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        appendDevolutiveModelsOptions();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [devolutiveModels]);
  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const resultForm = await api.get(`forms/${id}/`);
          setConfirmationMessage(
            resultForm.data.confirmation_message
              ? resultForm.data.confirmation_message
              : ""
          );
          let confirmation_message_code = devolutiveModels.find((value) => {
            return value.slug == resultForm.data.confirmation_message_mode;
          });
          if (confirmation_message_code) {
            changeShowFields(confirmation_message_code.slug);
            setValue(
              "confirmation_message_mode",
              appendDevolutiveModel(confirmation_message_code)
            );
            
          }
          setValue(
            "confirmation_message",
            resultForm.data.confirmation_message
              ? resultForm.data.confirmation_message
              : ""
          );
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id, devolutiveModels]);

  const appendDevolutiveModelsOptions = async () => {
    const result = devolutiveModels;

    let options = [];

    result.map((value) =>
      options.push({
        value: value?.slug,
        label: value?.name,
      })
    );

    setModelOptions(options);
  };

  const appendDevolutiveModel = (params) => {
    let options = {
      value: params?.slug,
      label: params?.name,
    };
    return options;
  };

  const changeShowFields = (value)=>{
    if(value=="sem_devolutiva"){
      setShowFields(false);
    }else{
      setShowFields(true);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container item spacing={3} xs={12} className={classes.grid}>
        <Grid item xs={12}>
          <Field.Select
            control={control}
            name="confirmation_message_mode"
            label="Modelo de Devolutiva *"
            errors={errors}
            options={modelOptions}
            onChange={(e)=>{
              setValue("confirmation_message_mode", e);
              changeShowFields(e.value);
            }}
            placeholder="Selecione o Tipo de Formulário"
          />
        </Grid>
        { showFields?
          (
          <Grid item xs={12} className={classes.editorBox}>
            <Field.Editor
              control={control}
              name="confirmation_message"
              label="Texto de Agradecimento *"
              errors={errors}
              defaultValue={confirmationMessage}
            />
          </Grid>
          ):(<></>)
        }
      </Grid>
    </Paper>
  );
};

export default Index;
