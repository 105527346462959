import React from "react";
import { useController } from "react-hook-form";

import Switch from "./styles";

const Interruptor = ({ control, name, defaultValue, ...defaultProps }) => {
    const {
        field: { ref, ...inputProps },
        // fieldState: { invalid, isTouched, isDirty },
        // formState: { touchedFields, dirtyFields },
    } = useController({
        name,
        control,
        // rules: { required: true },
        defaultValue: defaultValue,
    });

    return (
        <Switch
            {...inputProps}
            checked={inputProps.value}
            inputRef={ref}
            {...defaultProps}
        />
    );
};

export default Interruptor;
