import React from "react";
// import { useParams } from "react-router-dom";
import Headers from "../../../components/Header";
import OrderQuestions from "../../../domain/Form/OrderQuestions";
// fake data generator


const Order = () => {



  return (
    <>
      <Headers.List>Ordenar</Headers.List>
      <hr />
      <OrderQuestions />
    </>
  );
};
export default Order;
