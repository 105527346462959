/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { MdUndo, MdSave } from "react-icons/md";

import api from "../../services/api";

import schema from "./validation";
import Form from "../../components/Form";
import Field from "../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
    background: "#FFFFFF",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [optionsActions, setOptionsActions] = useState();
  const [inputHeaders, setInputHeaders] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const setOptionsToAction = async () => {
    const hookActionsResult = await api.get(`hooks_actions/`);

    let optionsAct = [];
    hookActionsResult.data.data.map((value) =>
      optionsAct.push({
        value: value?.uuid,
        label: value?.name,
      })
    );
    setOptionsActions(optionsAct);
  };

  React.useEffect(() => {
    (async () => {
      if (optionsActions == undefined) {
        await setOptionsToAction();
      }
      if (id) {
        try {
          const result = await api.get(`hooks/${id}/`);

          setValue("name", result.data.name);
          setValue("hook_action", appendHookAction(result.data.hook_action));
          // setValue("hook_actions", result.data.url);
          setValue("url", result.data.url);
          setValue("is_active", result.data.is_active);
          if (
            result.data["hook_headers"].length > 0 &&
            inputHeaders === undefined
          ) {
            let inp = [];
            result.data["hook_headers"].forEach((header, i) => {
              console.log(header);
              inp.push({
                id: header.uuid,
                name: header.name,
                value: header.value,
              });
            });
            setInputHeaders(inp);
          } else if (inputHeaders === undefined) {
            setInputHeaders([]);
          }
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [inputHeaders, id]);

  React.useEffect(() => {
    (async () => {
      setValue("name", "");
      setValue("hook_action", "");
      // setValue("hook_actions", result.data.url);
      setValue("url", "");
      setValue("is_active", true);
      setInputHeaders([]);
    })();
  }, []);

  const appendHookAction = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const onSubmit = async (data) => {
    data["hook_hook_headers"] = inputHeaders;
    if (id) {
      await api.patch(`hooks/${id}/`, data);
    } else {
      await api.post(`hooks/`, data);
    }

    props.history.push("/hook");
  };

  const handleButtonBack = () => {
    props.history.push("/hook");
  };

  const handleInputHeaderChange = (e, index) => {
    const { id, name, value } = e.target;
    const list = [...inputHeaders];
    list[index][name] = value;
    setInputHeaders(list);
  };

  // handle click event of the Remove button
  const handleRemoveHeaderClick = async (index) => {
    const list = [...inputHeaders];
    const val = list[index];
    if (val.id !== "") {
      await api.delete(`hooks_header/${val.id}/`);
      list.splice(index, 1);
    } else {
      list.splice(index, 1);
    }

    setInputHeaders(list);
  };

  // handle click event of the Add button
  const handleAddHeaderClick = () => {
    setInputHeaders([...inputHeaders, { id: "", name: " ", value: " " }]);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="name"
              label="Nome *"
              readOnly={true}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="url"
              label="URL *"
              readOnly={true}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Select
              control={control}
              options={optionsActions}
              name="hook_action"
              label="Ação *"
              readOnly={true}
              errors={errors}
            />
          </Grid>

          {id ? (
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name="is_active"
                label="Ativo"
                errors={errors}
                color="primary"
              />
            </Grid>
          ) : (
            <Field.Hidden control={control} name="active" defaultValue={true} />
          )}

          {inputHeaders?.map((x, i) => {
            return (
              <React.Fragment key={i}>
                <Grid item xs={4}>
                  <Field.Text
                    control={control}
                    name="name"
                    placeholder="Nome"
                    label="Nome do Header"
                    value={x.name}
                    onChange={(e) => handleInputHeaderChange(e, i)}
                    required={true}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field.Text
                    control={control}
                    name="value"
                    label="Valor"
                    placeholder="Valor"
                    value={x.value}
                    required={true}
                    onChange={(e) => handleInputHeaderChange(e, i)}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={3} style={{ marginTop: "25px" }}>
                  <Field.Hidden
                    control={control}
                    name="id"
                    placeholder="id"
                    value={x.id}
                    onChange={(e) => handleInputHeaderChange(e, i)}
                    errors={errors}
                  />
                  {inputHeaders.length !== 0 && (
                    <Field.Button
                      label="Remover"
                      onClick={() => handleRemoveHeaderClick(i)}
                    ></Field.Button>
                  )}
                
                </Grid>
              </React.Fragment>
            );
          })}
           <Field.Button
                label="Adicionar Header"
                onClick={handleAddHeaderClick}
            />
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            type="submit"
            label="Salvar"
            color="primary"
            startIcon={<MdSave />}
          />
        </Grid>
        {id ? (
          <Grid
            container
            item
            xs={6}
            direction="row"
            justifyContent="flex-end"
          ></Grid>
        ) : null}
      </Grid>
    </Form>
  );
};

export default Index;
