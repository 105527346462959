import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Box } from "@material-ui/core";
import { MdAdd, MdUndo, MdSave, MdContentCopy } from "react-icons/md";

import api from "../../../services/api";

import schema from "./validation";
import Form from "../../../components/Form";
import Field from "../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [applicationTypeOptions, setApplicationTypeOptions] = React.useState(
    []
  );
  const [formTypeOptions, setFormTypeOptions] = React.useState([]);
  const [interviewedTypeOptions, setInterviewedTypeOptions] = React.useState(
    []
  );

  React.useEffect(() => {
    (async () => {
      try {
        appendApplicationTypeOptions();
        appendFormTypeOptions();
        appendInterviewedTypeOptions();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const resultForm = await api.get(`forms/${id}/`);

          setValue("name", resultForm.data.name);
          // setValue("description", resultForm.data.description);
          setValue("form_type", appendFormType(resultForm.data.form_type));
          setValue(
            "interviewed_type",
            appendInterviewedType(resultForm.data.interviewed_type)
          );
          // setValue(
          //     "confirmation_message",
          //     resultForm.data.confirmation_message
          // );
          setValue("valeu", resultForm.data.valeu);
          setValue(
            "application_type",
            appendApplicationType(resultForm.data.application_type)
          );
          setValue("grace_time", parseInt(resultForm.data.grace_time));
          setValue("open", resultForm.data.open);
          setValue("only_company", resultForm.data.only_company);
          setValue("is_active", resultForm.data.is_active);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const appendApplicationTypeOptions = async () => {
    const result = await api.get("applications/types/");
    let options = [];

    result.data.data.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setApplicationTypeOptions(options);
  };

  const appendFormTypeOptions = async () => {
    const result = await api.get("forms/types/");
    let options = [];

    result.data.data.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setFormTypeOptions(options);
  };

  const appendInterviewedTypeOptions = async () => {
    const result = await api.get("interviewees/types/");
    let options = [];

    result.data.data.map((value) =>
      options.push({
        value: value?.uuid,
        label: value?.name,
      })
    );

    setInterviewedTypeOptions(options);
  };

  const appendApplicationType = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const appendFormType = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const appendInterviewedType = (params) => {
    let options = {
      value: params?.uuid,
      label: params?.name,
    };

    return options;
  };

  const onSubmit = async (data) => {
    if (id) {
      await api.patch(`forms/${id}/`, data);
      props.history.push("/forms");
    } else {
      let result = await api.post(`forms/`, data);
      if (result.data.uuid != undefined) {
        props.history.push(`/forms/update/${result.data.uuid}/`);
      }
    }
  };

  const handleButtonClone = async () => {
    const result = await api.get(`forms/${id}/clone/`);

    alert(`Formulário "${result.data.name}" clonado com sucesso `);

    props.history.push(`/forms/update/${result.data.uuid}`);
  };

  const handleButtonBack = () => {
    props.history.push("/forms");
  };

  const handleButtonNew = () => {
    props.history.push("/forms/create");
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={12}>
            <Field.Select
              control={control}
              name="application_type"
              label="Aplicação *"
              errors={errors}
              options={applicationTypeOptions}
              placeholder="Selecione a Aplicação"
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="name"
              label="Nome *"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Select
              control={control}
              name="form_type"
              label="Tipo de Formulário *"
              errors={errors}
              options={formTypeOptions}
              placeholder="Selecione o Tipo de Formulário"
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Select
              control={control}
              name="interviewed_type"
              label="Tipo de Entrevistado *"
              errors={errors}
              options={interviewedTypeOptions}
              placeholder="Selecione o Tipo de Entrevistado"
            />
          </Grid>
          <Field.Hidden
            control={control}
            name="value"
            errors={errors}
            defaultValue={0}
          />
          <Grid item xs={12}>
            <Field.Boolean
              control={control}
              name="is_public"
              label="Permitir o preenchimento sem necessidade de realizar login na ferramenta"
              errors={errors}
              color="primary"
            />
            <Box fontStyle="italic">
              Marque essa opção caso essa avaliação possa ser respondida de
              forma anônima sem a necessidade de fazer login na ferramenta
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Field.Boolean
              control={control}
              name="only_company"
              label="Somente Empresa"
              errors={errors}
              color="primary"
            />
            <Box fontStyle="italic">
              Marque essa opção caso essa avaliação possa ser respondida por uma
              pessoa Juridica (vínculo com empresa)
            </Box>
          </Grid>
          {id ? (
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name="is_active"
                label="Ativo"
                errors={errors}
                color="primary"
              />
              <Box fontStyle="italic">
                Para ativar ou desativar o formulário
              </Box>
            </Grid>
          ) : (
            <Field.Hidden
              control={control}
              name="is_active"
              defaultValue={true}
            />
          )}
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            type="submit"
            label="Salvar"
            color="primary"
            startIcon={<MdSave />}
          />
        </Grid>
        {id ? (
          <Grid container item xs={6} direction="row" justifyContent="flex-end">
            <Field.Button
              label="Novo Formulário"
              onClick={handleButtonNew}
              color="primary"
              startIcon={<MdAdd />}
            />
            <Field.Button
              type="button"
              label="Clonar"
              onClick={handleButtonClone}
              startIcon={<MdContentCopy />}
            />
          </Grid>
        ) : null}
      </Grid>
    </Form>
  );
};

export default Index;
