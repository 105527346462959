import React from "react";

import Sidebars from "../../../../components/Sidebar";
import MainListItems from "./listItems";

const Index = (props) => {
    const { isOpen } = props;

    return (
        <>
            <Sidebars.Div isHeader isOpen={isOpen}>
                &nbsp;
            </Sidebars.Div>

            <Sidebars.Sidebar isOpen={isOpen}>
                <Sidebars.Div slimScrollDiv>
                    <MainListItems isOpen={isOpen} />
                </Sidebars.Div>
            </Sidebars.Sidebar>
        </>
    );
};

export default Index;
