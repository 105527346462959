import React from "react";

import LabelComponent from "./Label";
import LabelCheckboxComponent from "./LabelCheckbox";
import ContextComponent from "./Content";
import ContextCheckboxComponent from "./ContentCheckbox";
import EditorComponent from "./Editor";
import InputComponent from "./Input";
import FileComponent from "./File";
import InputMaskComponent from "./InputMask";
import NumberMaskComponent from "./NumberMask";
import InterruptorComponent from "./Interruptor";
import CheckboxComponent from "./Checkbox";
import TextareaComponent from "./Textarea";
import SelectComponent from "./Select";
import HiddenComponent from "./Hidden";
import ButtonComponent from "../Button";
import SpanComponent from "./Span";

const Text = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        variant: "outlined",
        size: "small",
        defaultValue: defaultValue || "",
        error: fieldErrors ? true : false,
        ...rest,
    };

    return (
        <LabelComponent>
            {label ? <ContextComponent>{label}</ContextComponent> : null}
            <InputComponent control={control} name={name} {...defaultProps} />
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const File = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        type: "file",
        variant: "outlined",
        size: "small",
        defaultValue: defaultValue || "",
        error: fieldErrors ? true : false,
        ...rest,
    };

    return (
        <LabelComponent>
            {label ? <ContextComponent>{label}</ContextComponent> : null}
            <FileComponent control={control} name={name} {...defaultProps} />
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const Boolean = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        defaultValue: defaultValue || false,
        ...rest,
    };

    return (
        <LabelCheckboxComponent>
            <InterruptorComponent
                control={control}
                name={name}
                {...defaultProps}
            />
            <ContextCheckboxComponent>{label}</ContextCheckboxComponent>
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.value?.message}
                </SpanComponent>
            ) : null}
        </LabelCheckboxComponent>
    );
};

const Checkbox = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const i = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const f = Array.isArray(fieldName) ? fieldName[2] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        variant: "outlined",
        size: "small",
        defaultValue: defaultValue || false,
        error: fieldErrors ? true : false,
        ...rest,
    };

    return (
        <LabelCheckboxComponent>
            <CheckboxComponent
                control={control}
                name={name}
                {...defaultProps}
            />
            <ContextCheckboxComponent>{label}</ContextCheckboxComponent>
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.value?.message}
                </SpanComponent>
            ) : null}
        </LabelCheckboxComponent>
    );
};

const Textarea = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        variant: "outlined",
        size: "small",
        multiline: true,
        defaultValue: defaultValue !== undefined ? defaultValue : "",
        error: fieldErrors ? true : false,
        ...rest,
    };

    return (
        <LabelComponent>
            <ContextComponent>{label}</ContextComponent>
            <TextareaComponent
                control={control}
                name={name}
                {...defaultProps}
            />
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const Select = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        variant: "outlined",
        size: "small",
        defaultValue:
            defaultValue !== undefined
                ? defaultValue
                : rest.isMulti
                ? []
                : null,
        error: fieldErrors ? true : false,
        ...rest,
    };

    return (
        <LabelComponent>
            <ContextComponent>{label}</ContextComponent>
            <SelectComponent control={control} name={name} {...defaultProps} />
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.value?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const Hidden = ({ control, name, defaultValue, ...rest }) => {
    const defaultProps = {
        type: "hidden",
        defaultValue: defaultValue !== undefined ? defaultValue : "",
        ...rest,
    };

    return <HiddenComponent control={control} name={name} {...defaultProps} />;
};

const Button = ({ label, ...rest }) => {
    const defaultProps = {
        variant: "contained",
        size: "small",
        ...rest,
    };

    return <ButtonComponent label={label} {...defaultProps} />;
};

const Editor = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const defaultProps = {
        // variant: "outlined",
        // size: "small",
        // multiline: true,
        defaultValue: defaultValue || "",
        setContents: defaultValue || "",
        lang: "pt_br",
        height: "17rem",
        setOptions: {
            buttonList: [
                ["undo", "redo"],
                ["font", "fontSize", "formatBlock"],
                [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "removeFormat",
                ],
                // "/", // Line break
                [
                    "fontColor",
                    "hiliteColor",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "table",
                ],
                [
                    "link",
                    // "image",
                    // "video",
                ],
                // [
                //     "fullScreen",
                //     "showBlocks",
                //     "codeView",
                //     "preview",
                //     "print",
                //     // "save",
                // ],
            ],
        },
        // error: errors[name] ? true : false,
        ...rest,
    };

    return (
        <LabelComponent>
            <ContextComponent>{label}</ContextComponent>
            <EditorComponent control={control} name={name} {...defaultProps} />
            {errors[name] ? (
                <SpanComponent error={true}>
                    {errors[name]?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const TextMask = ({ control, label, name, errors, defaultValue, ...rest }) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        variant: "outlined",
        size: "small",
        defaultValue: defaultValue || "",
        error: fieldErrors ? true : false,
        ...rest,
    };

    return (
        <LabelComponent>
            {label ? <ContextComponent>{label}</ContextComponent> : null}
            <InputMaskComponent
                control={control}
                name={name}
                {...defaultProps}
            />
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const NumberMask = ({
    control,
    label,
    name,
    errors,
    defaultValue,
    mask,
    ...rest
}) => {
    const fieldName = name.indexOf(".") !== -1 ? name.split(".") : name;
    const filedLength = Array.isArray(fieldName) ? fieldName.length : 0;
    const a = Array.isArray(fieldName) ? fieldName[0] : fieldName;
    const t = Array.isArray(fieldName) ? parseInt(fieldName[1]) : undefined;
    const i = Array.isArray(fieldName)
        ? parseInt(fieldName[filedLength - 2])
        : undefined;
    const f = Array.isArray(fieldName) ? fieldName[filedLength - 1] : undefined;
    const fieldErrors = Array.isArray(fieldName)
        ? filedLength === 4
            ? errors[a]?.[t]?.[i]?.[f]
            : errors[a]?.[i]?.[f]
        : errors[a];
    const defaultProps = {
        variant: "outlined",
        size: "small",
        defaultValue: defaultValue || "",
        error: fieldErrors ? true : false,
        maskProps: mask,
        ...rest,
    };

    return (
        <LabelComponent>
            {label ? <ContextComponent>{label}</ContextComponent> : null}
            <NumberMaskComponent
                control={control}
                name={name}
                {...defaultProps}
            />
            {fieldErrors ? (
                <SpanComponent error={true}>
                    {fieldErrors?.message}
                </SpanComponent>
            ) : null}
        </LabelComponent>
    );
};

const Field = {
    Text,
    Boolean,
    Checkbox,
    Textarea,
    Select,
    Hidden,
    Button,
    Editor,
    TextMask,
    NumberMask,
    File,
};

export default Field;
