import styled from "styled-components";

const Label = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

export default Label;
