import * as Yup from "yup";

export default Yup.object().shape({
  application_type: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }), 
  confirmation_message_mode: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  name: Yup.string()
    .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    .required("Campo obrigatório."),
  form_type: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  interviewed_type: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  value: Yup.string().required("Campo obrigatório."),
  description: Yup.string().required("Campo obrigatório."),
  confirmation_message: Yup.string(),
  is_readonly: Yup.boolean(),
  only_company: Yup.boolean(),
  is_active: Yup.boolean(),

  time_reply:  Yup.number().typeError("O valor dever um número"),
  email_reminder: Yup.string(),
  is_public: Yup.boolean(),
  add_sas: Yup.boolean(),
  market_comparison: Yup.boolean(),

  grace_time: Yup.number().typeError("O valor dever um número"),
  review_editing_time: Yup.number().typeError("O valor dever um número"),
  qty_recommendations: Yup.number().typeError("O valor dever um número"),
  min_products: Yup.number().typeError("O valor dever um número"),
  max_products: Yup.number().typeError("O valor dever um número"),
  validity: Yup.number().typeError("O valor dever um número"),

  questions: Yup.array().of(
    Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string().required("Campo obrigatório."),
        // order: Yup.number().required("Campo obrigatório."),
        value: Yup.number()
          .typeError("O valor deve ser um número")
          .test(
            "maxDigits",
            "O valor deve ter no máximo 2 casas decimais.",
            (number) => Number.isInteger(number * 10 ** 2)
          )
          .min(0, "O valor mínimo deve ser 0.")
          .max(100, "O valor máximo deve ser 100.")
          .required("Campo obrigatório."),
        is_active: Yup.boolean(),
        is_required: Yup.boolean(),
      })
    )
  ),
});
