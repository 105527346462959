import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { MdList } from "react-icons/md";
// import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Field from "../../../../../components/Field";


const Answer =
  ({
    control,
    offeredAnswer,
    index,
    classes,
    errors,
    handleOfferedAnswersRemove,
    handleShowSolutionModal,
    handleShowPainModal,
  }) => {
    return (
      <Paper className={classes.paper} key={offeredAnswer.uuid}>
        <Grid container spacing={1} item xs={12}>
          <Field.Hidden
            control={control}
            name={`offeredAnswers.${index}.uuid`}
            defaultValue={offeredAnswer.uuid}
          />
          <Field.Hidden
            control={control}
            name={`offeredAnswers.${index}.order`}
            defaultValue={offeredAnswer.order}
          />
          <Grid item xs={1}>
            <Field.NumberMask
              control={control}
              name={`offeredAnswers.${index}.percent`}
              label="Peso(%) *"
              errors={errors}
              defaultValue={offeredAnswer.percent}
              mask={{
                allowDecimal: false,
                decimalSymbol: ".",
                decimalLimit: 0,
                requireDecimal: false,
                integerLimit: 3,
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Field.Text
              control={control}
              name={`offeredAnswers.${index}.name`}
              label="Título da Pergunta *"
              errors={errors}
              defaultValue={offeredAnswer.name}
            />
          </Grid>
          <Grid
            container
            item
            xs={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Field.Button
              label="Remover Opção"
              color="secondary"
              onClick={()=>{handleOfferedAnswersRemove(index)}}
            /> 
           
          </Grid>
          <Grid item xs={4}>
            <Field.Boolean
              control={control}
              name={`offeredAnswers.${index}.annul_question`}
              label="Caso o usuário escolha essa opção, a pergunta deverá ser anulada."
              errors={errors}
              color="primary"
              defaultValue={offeredAnswer.annul_question}
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Boolean
              control={control}
              name={`offeredAnswers.${index}.has_feedback`}
              label="Marque esse campo caso deseje que o usuário complemente a resposta."
              errors={errors}
              color="primary"
              defaultValue={offeredAnswer.has_feedback}
            />
          </Grid>
          <Grid
            container
            item
            xs={4}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Field.Button
              label="Associar Solução"
              onClick={() => handleShowSolutionModal(offeredAnswer, index)}
              startIcon={<MdList />}
            />
            <Field.Button
              label="Associar Dor"
              onClick={() => handleShowPainModal(offeredAnswer, index)}
              startIcon={<MdList />}
            />
          </Grid>
          <Grid item xs={12}>
            <Field.Textarea
              control={control}
              name={`offeredAnswers.${index}.feedback`}
              label="Feedback"
              errors={errors}
              rows={4}
              defaultValue={offeredAnswer.feedback}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

const AnswersList = 
  ({
    offeredAnswersFields,
    control,
    classes,
    errors,
    handleOfferedAnswersRemove,
    handleShowSolutionModal,
    handleShowPainModal,
  }) => {
    if (offeredAnswersFields) {
      return (
        <div>
          {offeredAnswersFields.map((offeredAnswer, ind) => {
            return (
              <Answer
                control={control}
                offeredAnswer={offeredAnswer}
                index={ind}
                key={`item-${offeredAnswer.uuid}`}
                classes={classes}
                errors={errors}
                handleOfferedAnswersRemove={handleOfferedAnswersRemove}
                handleShowSolutionModal={handleShowSolutionModal}
                handleShowPainModal={handleShowPainModal}
              />
            );
          })}
        </div>
      );
    } else {
      return <> </>;
    }
  }

export { Answer, AnswersList };
