import styled from "styled-components";

const width = "15.1875rem";
const width_collapse = "3.75rem";

const Header = styled.header`
    height: 5rem;
    position: relative;
    padding: 0 0.9375rem;
    z-index: 1;
    background: #ffffff;
    transition: all 0.2s ease;
    margin-left: ${(props) => (props.isOpen ? width : width_collapse)};
    width: calc(100% - ${(props) => (props.isOpen ? width : width_collapse)});
`;

const Div = styled.div`
    height: 100%;

    ${({ isControl }) =>
        isControl &&
        `
        height: unset;
        cursor: pointer;
        position: absolute;
        left: 1.4375rem;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
    `}

    ${({ float, left }) =>
        float &&
        left &&
        `
        float: left;
        margin-left: 3.125rem;
    `}

    ${({ float, right }) =>
        float &&
        right &&
        `
        float: right;
        line-height: 3rem;
        margin-top: 0;
    `}
`;

const Ul = styled.ul`
    ${({ clearfix }) =>
        clearfix &&
        `
        height: 100%;
        margin: 0;
    `}
`;

const Li = styled.li`
    ${({ float, left }) =>
        float &&
        left &&
        `
        float: left;
    `}

    ${({ isIcons }) =>
        isIcons &&
        `
        margin: 0;
        padding: 0 0.3125rem;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
    `}

    ${({ isIconsControl }) =>
        isIconsControl &&
        `
        cursor: pointer;
    `}
`;

const List = styled.header`
    flex-grow: 1;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
`;

const Form = styled(List)``;

const Delete = styled(List)``;

const Grid = styled(List)``;

const Headers = {
    Header,
    Div,
    Ul,
    Li,
    List,
    Form,
    Delete,
    Grid,
};

export default Headers;
