/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { IconButton } from "@material-ui/core";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Field from "../../../components/Field";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";

const List = (props) => {
  const { path } = props.match;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      filterable: false,
      sortable: false,
    },
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "form",
      headerName: "Formulário",
      flex: 1,
      renderCell: (params) => (
          <>
            {params.row.form.name}
          </>
      ),
    },
    {
      field: "is_active",
      headerName: "Ativo",
      type: "boolean",
      sortable: false,
      width: 100,
    },
    {
      field: "modified",
      headerName: "Modificado em",
      type: "dateTime",
      width: 180,
      valueFormatter: (params) =>
        new Intl.DateTimeFormat("default", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }).format(new Date(params.value)),
    },
    {
      field: "uuid",
      headerName: "Ações",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <>
          <Link to={`${path}/update/${params.value}`}>
            <IconButton size="small" color="primary">
              <MdEdit size={24} />
            </IconButton>
          </Link>
          <Link to={`${path}/delete/${params.value}`}>
            <IconButton size="small" color="secondary">
              <MdDelete size={24} />
            </IconButton>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Sections.List>
        <Headers.List>Campanhas</Headers.List>
        <Link to={`${path}/create`}>
          <Field.Button
            label="Nova Campanha"
            color="primary"
            startIcon={<MdAdd />}
          />
        </Link>
      </Sections.List>

      <Article>
        <CustomDataGrid columns={columns} path={"campaigns/"} />
      </Article>
    </>
  );
};

export default List;
