import * as Yup from "yup";

export default Yup.object().shape({
    name: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."), 
    code: Yup.string()
        .min(1, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    is_active: Yup.boolean(),
});
