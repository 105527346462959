import styled from "styled-components";

const Content = styled.span`
    display: inline-block;
    max-width: 100%;
    width: 100%;
    font-weight: 700;
    margin-left: 5px;
`;

export default Content;
