import * as Yup from "yup";

export default Yup.object().shape({
    name: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    url: Yup.string()
        .min(2, "O campo precisa ter no mínimo 2 caracteres.")
        .required("Campo obrigatório."),
    // hook_action: Yup.string()
    //     .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    //     .required("Campo obrigatório."),
    active: Yup.boolean(),
});