/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { MdUndo, MdSave } from "react-icons/md";

import api from "../../../services/api";

// import schema from "./validation";
// import schemaNew from "./validationNew";
import schema from "./validationCompany";
import Form from "../../../components/Form";
import Field from "../../../components/Field";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const CompanyForm = (props) => {
  const classes = useStyles();
  const { id, externalUserId, companyId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [userId, setUserId] = useState(id);

  React.useEffect(() => {
    (async () => {
      if (companyId) {
        try {
          const result = await api.get(`external/company/${companyId}/`);
          setValue("name", result.data.name);
          setValue("email", result.data.email);
          setValue("document", result.data.document);
          setValue("phone", result.data.phone);
          // setValue("is_consultant", result.data.is_consultant);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [companyId]);
  React.useEffect(() => {
    (async () => {
      if (id) {
        setUserId(id);
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    if (companyId == undefined) {
      //   data["is_consultant"] = true;
      try {
        const response = await api.post(
          `external/company/addCompanyUser/${externalUserId}/`,
          data
        );

        if (response.error == true) {
          alert(response.message);
        } else {
          props.history.push(`/users/consultants/update/${userId}`);
        }
      } catch (e) {
        alert("Erro ao salvar a empresa");
      }
    } else {
      const response = await api.patch(`external/company/${companyId}/`, data);
      if (response.error == true) {
        alert(response.message);
      } else {
        props.history.push(`/users/consultants/update/${userId}`);
      }
    }

    // props.history.push("/users/consultants");
  };

  const handleButtonBack = () => {
    props.history.push(`/users/consultants/update/${userId}`);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
      <Paper className={classes.paper}>
        <Grid
          container
          item
          spacing={3}
          xs={12}
          className={classes.grid}
          elevation={3}
        >
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="name"
              placeholder="Nome"
              label="Nome"
              required={true}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.Text
              control={control}
              name="email"
              placeholder="E-mail"
              label="E-mail"
              required={true}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.TextMask
              mask={"99.999.999/9999-99"}
              control={control}
              name="document"
              label="CNPJ"
              required={true}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Field.TextMask
              control={control}
              mask={"(99)9 9999-9999"}
              name="phone"
              placeholder="Telefone"
              label="Telefone"
              required={true}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid item xs={12}>
        <Field.Button
          label="Voltar"
          onClick={handleButtonBack}
          startIcon={<MdUndo />}
        />
        <Field.Button
          type="submit"
          label="Salvar"
          color="primary"
          startIcon={<MdSave />}
        />
      </Grid>
    </Form>
  );
};

export default CompanyForm;
