import React from "react";
// import { Redirect } from "react-router-dom";

import history from "../../../../history";
import * as Icons from "react-icons/fa";

import Sidebars from "../../../../components/Sidebar";

const MenuItems = [
    {
        id: "1000",
        name: "Dashboard",
        path: "/dashboard",
        icon: "FaTachometerAlt",
        childrens: false,
        disabled: false,
    },
    {
        id: "2000",
        name: "Pessoas",
        path: false,
        icon: "FaUsers",
        childrens: [
            {
                name: "Usuários",
                path: "/users",
                icon: "FaUserFriends",
                childrens: false,
                disabled: false,
            }, 
            {
                name: "Usuarios Clientes",
                path: "/users/clients",
                icon: "FaUserFriends",
                childrens: false,
                disabled: false,
            },
            {
                name: "Consultores",
                path: "/users/consultants",
                icon: "FaUserFriends",
                childrens: false,
                disabled: false,
            },
        ],
        disabled: false,
    },
    {
        id: "3000",
        name: "Cadastros",
        path: false,
        icon: "FaListAlt",
        childrens: [
            {
                name: "Campanhas",
                path: "/campaigns",
                icon: "FaListAlt",
                childrens: false,
                disabled: false,
            },
            {
                name: "Formulários",
                path: "/forms",
                icon: "FaBook",
                childrens: false,
                disabled: false,
            },
            {
                name: "Perguntas",
                path: "/questions",
                icon: "FaQuestionCircle",
                childrens: false,
                disabled: false,
            },
        ],
        disabled: false,
    },
    {
        id: "4000",
        name: "Relatórios",
        path: false,
        icon: "FaRegChartBar",
        childrens: [
            {
                name: "Agrupados",
                path: "/dashboard",
                icon: "FaRegCircle",
                childrens: false,
                disabled: true,
            },
            {
                name: "Por Avaliações",
                path: "/dashboard",
                icon: "FaRegCircle",
                childrens: false,
                disabled: true,
            },
        ],
        disabled: true,
    },
    {
        id: "5000",
        name: "Configurações",
        path: false,
        icon: "FaSlidersH",
        childrens: [
            {
                name: "Tipos de Aplicação",
                path: "/types/applications",
                icon: "FaBuilding",
                childrens: false,
                disabled: false,
            },
            {
                name: "Tipos de Produtos",
                path: "/types/products",
                icon: "FaBuilding",
                childrens: false,
                disabled: false,
            },
            {
                name: "Tipos de Formulário",
                path: "/types/forms",
                icon: "FaBuilding",
                childrens: false,
                disabled: false,
            },
            {
                name: "Tipos de Pergunta",
                path: "/types/questions",
                icon: "FaBuilding",
                childrens: false,
                disabled: false,
            },
            {
                name: "Tipos de Entrevistado",
                path: "/types/interviewees",
                icon: "FaBuilding",
                childrens: false,
                disabled: false,
            },
            {
                name: "Dores",
                path: "/pains",
                icon: "FaBook",
                childrens: false,
                disabled: false,
            },
            {
                name: "Soluções",
                path: "/solutions",
                icon: "FaBook",
                childrens: false,
                disabled: false,
            },
            {
                name: "Temas/Áreas",
                path: "/themes",
                icon: "FaSitemap",
                childrens: false,
                disabled: false,
            },
            {
                name: "Tags",
                path: "/tags",
                icon: "FaSitemap",
                childrens: false,
                disabled: false,
            },    
            {
                name: "Termos LGPD",
                path: "/lgpd_term",
                icon: "FaSitemap",
                childrens: false,
                disabled: false,
            },
            {
                name: "Destinatários",
                path: "/dashboard",
                icon: "FaEnvelope",
                childrens: false,
                disabled: true,
            },   
            {
                name: "Hooks",
                path: "/hook",
                icon: "FaEnvelope",
                childrens: false,
                disabled: false,
            },  
            {
                name: "Modelos de E-mail",
                path: "/mail_models",
                icon: "FaEnvelope",
                childrens: false,
                disabled: false,
            },
        ],
        disabled: false,
    },
];

const ListItems = (props) => {
    const [open, setOpen] = React.useState(false);
    const { id, name, childrens, path, icon, isOpen, first, disabled } = props;
    const nested = props.nested || false;
    const DynamicIcon = Icons[icon];

    const cols = childrens !== false ? parseInt(childrens.length / 7) + 1 : 1;

    const handleClick = () => {
        if (disabled !== true) {
            if (childrens !== false) {
                setOpen(!open);
            } else {
                history.push(path);
            }
        }
    };

    return (
        <Sidebars.Li nested={nested} isOpen={isOpen} first={first} cols={cols}>
            <Sidebars.Span
                onClick={handleClick}
                hasArrow={childrens}
                isOpen={isOpen}
                open={open}
                nested={nested}
                isDisabled={disabled}
            >
                {!nested ? <DynamicIcon size="24" /> : null}
                <Sidebars.Span
                    navText
                    isOpen={isOpen}
                    nested={nested}
                    isDisabled={disabled}
                >
                    {name}
                </Sidebars.Span>
            </Sidebars.Span>

            {childrens !== false && disabled !== true ? (
                <Sidebars.Ul submenu isOpen={isOpen} open={open}>
                    {childrens.map((item, index) => (
                        <ListItems
                            key={parseInt(id) + parseInt(index)}
                            isOpen={isOpen}
                            nested
                            first={!index}
                            {...item}
                        />
                    ))}
                </Sidebars.Ul>
            ) : null}
        </Sidebars.Li>
    );
};

const MainListItems = (props) => {
    const { isOpen } = props;

    return (
        <Sidebars.Div nkNavScroll>
            <Sidebars.Ul menu isOpen={isOpen}>
                {MenuItems.map((item, index) => (
                    <ListItems
                        key={item.id}
                        first={!index}
                        {...props}
                        {...item}
                    />
                ))}
            </Sidebars.Ul>
        </Sidebars.Div>
    );
};

export default MainListItems;
