import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "./components/List";
import ListClients from "./components/ListClients";
import ListConsultants from "./components/ListConsultants";
import Form from "./components/Form";
import FormConsultant from "./components/FormConsultant";
import Delete from "./components/Delete";
import DeleteCompany from "./components/DeleteCompany";
import FormCompany from "./components/FormCompany";

const Index = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route exact path={`${path}/clients`} component={ListClients} />
      <Route exact path={`${path}/clients/update/:id`} component={Form} />
      <Route exact path={`${path}/update/:id`} component={Form} />
      <Route exact path={`${path}/delete/:id`} component={Delete} />
      <Route exact path={`${path}/consultants`} component={ListConsultants} />
      <Route
        exact
        path={`${path}/consultants/update/:id`}
        component={FormConsultant}
      />
      <Route
        exact
        path={`${path}/consultants/create`}
        component={FormConsultant}
      />
      <Route exact path={`${path}/consultants/delete/:id`} component={Delete} />
      <Route exact path={`${path}/consultants/:id/:externalUserId/company`} component={FormCompany} />
      <Route exact path={`${path}/consultants/:id/:externalUserId/company/:companyId`} component={FormCompany} />
      <Route exact path={`${path}/consultants/:id/:externalUserId/company/delete/:companyId`} component={DeleteCompany} />
    </Switch>
  );
};

export default Index;
