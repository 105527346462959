import React from "react";
import { useController } from "react-hook-form";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import Fields from "./styles";

const TextMaskCustom = (props) => {
    const { inputRef, maskProps, ...other } = props;

    const numberMask = createNumberMask({
        prefix: maskProps.prefix || "",
        suffix: maskProps.suffix || "",
        includeThousandsSeparator: maskProps.includeThousandsSeparator || false,
        thousandsSeparatorSymbol: maskProps.thousandsSeparatorSymbol || " ",
        allowDecimal: maskProps.allowDecimal || false,
        decimalSymbol: maskProps.decimalSymbol || ".",
        decimalLimit: maskProps.decimalLimit || 2, // how many digits allowed after the decimal
        requireDecimal: maskProps.requireDecimal || false,
        allowNegative: maskProps.allowNegative || false,
        allowLeadingZeroes: maskProps.allowLeadingZeroes || false,
        integerLimit: maskProps.integerLimit || null, // limit length of integer numbers
        maxValue: maskProps.maxValue || null,
    });

    return (
        <Fields.Mask
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={numberMask}
            guide={true}
        />
    );
};

const Input = ({ control, name, defaultValue, maskProps, ...defaultProps }) => {
    const {
        field: { ref, ...inputProps },
        // fieldState: { invalid, isTouched, isDirty },
        // formState: { touchedFields, dirtyFields },
    } = useController({
        name,
        control,
        // rules: { required: true },
        defaultValue: defaultValue,
    });

    return (
        <Fields.Text
            {...inputProps}
            inputRef={ref}
            {...defaultProps}
            InputProps={{
                inputProps: { maskProps },
                inputComponent: TextMaskCustom,
            }}
        />
    );
};

export default Input;
