import React from "react";
import { Link } from "react-router-dom";
import {
  MdAdd,
  MdEdit,
  MdDelete,
  MdContentCopy,
  MdSwapVert,
} from "react-icons/md";
import { IconButton, Link as LinkButton } from "@material-ui/core";

import api from "../../../services/api";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Field from "../../../components/Field";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";

const List = (props) => {
  const { path } = props.match;

  const handleButtonClone = async (uuid) => {
    const result = await api.get(`questions/${uuid}/clone/`);

    alert(`Pergunta "${result.data.name}" clonada com sucesso `);

    props.history.push(`/questions/update/${result.data.uuid}`);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      filterable: false,
      sortable: false,
    },
    { field: "name", headerName: "Título da Pergunta", flex: 1 },
    { field: "question_type", headerName: "Tipo de Questão", flex: 1, renderCell: (params) => {
      return (<>{params.row.question_type.name}</>)
    } },
    {
      field: "modified",
      headerName: "Modificado em",
      type: "date",
      width: 180,
      valueFormatter: (params) =>
        new Intl.DateTimeFormat("default", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }).format(new Date(params.value)),
    },

    {
      field: "uuid",
      headerName: "Ações",
      filterable: false,
      sortable: false,
      width: 135,
      renderCell: (params) => {
        return (
          <>
            <Link to={`${path}/update/${params.value}`}>
              <IconButton size="small" color="primary" title="Editar Pergunta">
                <MdEdit size={24} />
              </IconButton>
            </Link>
            {params.row.question_type.has_offered_answer == true ? (
              <Link
                to={`${path}/OrderAnswers/${params.value}`}
                title="Ordenar Alternativas"
              >
                <IconButton size="small" color="primary">
                  <MdSwapVert size={24}></MdSwapVert>
                </IconButton>
              </Link>
            ) : (
              ""
            )}
            <Link
              to={`${path}/delete/${params.value}`}
              title="Excluír Pergunta"
            >
              <IconButton size="small" color="secondary">
                <MdDelete size={24} />
              </IconButton>
            </Link>
            <LinkButton
              onClick={() => handleButtonClone(params.value)}
              title="Clonar Pergunta"
            >
              <IconButton size="small" color="default">
                <MdContentCopy size={24} />
              </IconButton>
            </LinkButton>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Sections.List>
        <Headers.List>Perguntas</Headers.List>
        <Link to={`${path}/create`}>
          <Field.Button
            label="Nova Pergunta"
            color="primary"
            startIcon={<MdAdd />}
          />
        </Link>
      </Sections.List>

      <Article>
        <CustomDataGrid columns={columns} path={"questions/"} />
      </Article>
    </>
  );
};

export default List;
