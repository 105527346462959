import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { MdAdd, MdUndo, MdSave } from "react-icons/md";

import api from "../../../services/api";

import schema from "./validation";
import Form from "../../../components/Form";
import Field from "../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
  editorBox: {
    zIndex: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [term, setTerm] = React.useState("");

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`lgpd_term/${id}/`);

          setValue("title", result.data.title);
          setTerm(result.data.term);
          setValue("is_active", result.data.is_active);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      await api.patch(`lgpd_term/${id}/`, data);
    } else {
      await api.post(`lgpd_term/`, data);
    }

    props.history.push("/lgpd_term");
  };

  const handleButtonBack = () => {
    props.history.push("/lgpd_term");
  };

  const handleButtonNew = () => {
    props.history.push("/lgpd_term/create");
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="title"
              label="Titulo *"
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} className={classes.editorBox}>
            <Field.Editor
              control={control}
              name="term"
              label="Termo *"
              errors={errors}
              defaultValue={term}
            />
          </Grid>
          {id ? (
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name="is_active"
                label="Ativo"
                errors={errors}
                color="primary"
              />
            </Grid>
          ) : (
            <Field.Hidden
              control={control}
              name="is_active"
              defaultValue={true}
            />
          )}
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            type="submit"
            label="Salvar"
            color="primary"
            startIcon={<MdSave />}
          />
        </Grid>
        {id ? (
          <Grid container item xs={6} direction="row" justifyContent="flex-end">
            <Field.Button
              label="Nova Tag"
              onClick={handleButtonNew}
              color="primary"
              startIcon={<MdAdd />}
            />
          </Grid>
        ) : null}
      </Grid>
    </Form>
  );
};

export default Index;
