import styled from "styled-components";
import ReactSelect from "react-select";

export default styled(ReactSelect)`
    height: 2.5rem;

    ${({ error }) =>
        error &&
        `
        & > div {
            border-color: #f00 !important;
        }
    `}
`;
