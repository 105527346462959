import React from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Box } from "@material-ui/core";

import api from "../../../../../services/api";

import Field from "../../../../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [description, setDescription] = React.useState("");
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const resultForm = await api.get(`forms/${id}/`);

          setValue(
            "description",
            resultForm.data.description ? resultForm.data.description : ""
          );
          setDescription(
            resultForm.data.description ? resultForm.data.description : ""
          );
          setValue("time_reply", resultForm.data.time_reply);
          setValue("email_reminder", resultForm.data.email_reminder);
          setValue("is_public", resultForm.data.is_public);
          setValue("grace_time", parseInt(resultForm.data.grace_time));
          setValue("review_editing_time", resultForm.data.review_editing_time);
          setValue("add_sas", resultForm.data.add_sas);
          setValue("market_comparison", resultForm.data.market_comparison);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);
 
  return (
    <Paper className={classes.paper}>
      <Grid container item spacing={3} xs={12} className={classes.grid}>
        <Grid item xs={12}>
          <Field.Editor
            control={control}
            name="description"
            label="Descrição *"
            errors={errors}
            rows={4}
            defaultValue={description}
          />
        </Grid>
        <Grid item xs={12}>
          <Field.NumberMask
            control={control}
            name="time_reply"
            label="Tempo estimado para responder (em minutos)"
            errors={errors}
            mask={{
              allowDecimal: false,
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 3,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field.Boolean
            control={control}
            name="email_reminder"
            label="Enviar lembrete por e-mail"
            errors={errors}
            color="primary"
          />
          <Box fontStyle="italic">
            Caso esse campo esteja marcado, e o usuário aceitar o recebimento de
            e-mails, será enviado um lembrete, 2 dias antes do prazo final,
            sinalizando que a avaliação não foi finalizada!
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field.Boolean
            control={control}
            name="add_sas"
            label="Adicionar no SAS"
            errors={errors}
            color="primary"
          />
          <Box fontStyle="italic">
            Marque essa opção caso queira registrar o atendimento no sas. Não
            esqueça de cadastrar a url do formulário no integra.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Field.Boolean
            control={control}
            name="market_comparison"
            label="Fazer comparação com o mercado"
            errors={errors}
            color="primary"
          />
          <Box fontStyle="italic">
            Marque essa opção para inserir a comparação de mercado na
            devolutiva.
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field.NumberMask
            control={control}
            name={`qty_recommendations`}
            label="Quantidade máxima de soluções por tema"
            errors={errors}
            mask={{
              allowDecimal: false,
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 5,
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Field.NumberMask
            control={control}
            name={`min_products`}
            label="Quantidade mínima de Produtos"
            errors={errors}
            mask={{
              allowDecimal: false,
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 5,
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Field.NumberMask
            control={control}
            name={`max_products`}
            label="Quantidade máxima de Produtos indicados na devolutiva"
            errors={errors}
            mask={{
              allowDecimal: false,
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 5,
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Field.NumberMask
            control={control}
            name={`validity`}
            label="Validade do diagnostico (meses)"
            errors={errors}
            mask={{
              allowDecimal: false,
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 5,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field.NumberMask
            control={control}
            name="grace_time"
            label="Prazo de Carência (em Dias)"
            errors={errors}
            mask={{
              allowDecimal: false,
              suffix: "",
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 3,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field.NumberMask
            control={control}
            name="review_editing_time"
            label="Tempo para Edição da avaliação (em Dias)"
            errors={errors}
            mask={{
              allowDecimal: false,
              suffix: "",
              decimalSymbol: ".",
              decimalLimit: 0,
              requireDecimal: false,
              integerLimit: 3,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Index;
