import React from "react";
import { Link } from "react-router-dom";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { IconButton } from "@material-ui/core";

import Sections from "../../../components/Section";
import Headers from "../../../components/Header";
import Article from "../../../components/Article";
import CustomDataGrid from "../../../components/DataGrid";
import Field from "../../../components/Field";

const List = (props) => {
  const { path } = props.match;

  const getFullName = (params) => {
    return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
  };

  const columns = [
    { field: "username", headerName: "Login", width: 180 },
    {
      field: "full_name",
      headerName: "Nome Completo",
      flex: 1,
      valueGetter: getFullName,
      sortComparator: (v1, v2, c1, c2) =>
        getFullName(c1).localeCompare(getFullName(c2)),
    },
    { field: "email", headerName: "E-Mail", flex: 1 },
    {
      field: "date_joined",
      headerName: "Criado em",
      type: "date",
      width: 180,
      valueFormatter: (params) =>
        new Intl.DateTimeFormat("default", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }).format(new Date(params.value)),
    },
    {
      field: "uuid",
      headerName: "Ações",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <>
          <Link to={`${path}/update/${params.value}`}>
            <IconButton size="small" color="primary">
              <MdEdit size={24} />
            </IconButton>
          </Link>
          <Link to={`${path}/delete/${params.value}`}>
            <IconButton size="small" color="secondary">
              <MdDelete size={24} />
            </IconButton>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Sections.List>
        <Headers.List>Usuários Consultores</Headers.List>
        <Link to={`${path}/create`}>
          <Field.Button label="Novo" color="primary" startIcon={<MdAdd />} />
        </Link>
      </Sections.List>

      <Article>
        <CustomDataGrid columns={columns} path={"users/2/"} />
      </Article>
    </>
  );
};

export default List;
