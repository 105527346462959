import React from "react";
import { DataGrid, ptBR } from "@material-ui/data-grid";
import {
    createTheme,
    ThemeProvider,
    makeStyles,
} from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import api from "../../services/api";

const theme = createTheme({}, ptBR);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    paper: {
        flex: 1,
        padding: theme.spacing(2),
        height: "calc(100vh - 13rem);",
    },
}));

export default function CustomDataGrid(props) {
    const classes = useStyles();
    const { columns, path, reload, setReload, pathParams } = props;
    const rowsPerPageOptions = props.rowsPerPageOptions || [10, 20, 30];
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(rowsPerPageOptions[0]);
    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [sortModel, setSortModel] = React.useState([]);
    const [filterValue, setFilterValue] = React.useState();

    const [orderServer, setOrderServer] = React.useState({});
    const [filterServer, setFilterServer] = React.useState({});

    const modal = props.modal || false;

    const handlePageChange = (params) => {
        setPage(params.page);
    };

    const handlePageSizeChange = (params) => {
        setPageSize(params.pageSize);
        setPage(0);
    };

    const handleSortModelChange = (newModel) => {
        if (newModel !== sortModel) {
            let order = {};

            if (newModel[0] !== undefined) {
                order = {
                    order: newModel[0].field,
                    sort: newModel[0].sort,
                };
            }

            setOrderServer(order);
            setSortModel(newModel);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleFilterModelChange = React.useCallback((filterModel) => {
        const item = filterModel.items[0];

        if (item !== undefined) {
            const filter = {
                filter: item.columnField,
                operator: item.operatorValue,
                value: item.value,
            };

            setFilterServer(filter);
            setFilterValue(item.value);
        } else {
            setFilterServer({});
            setFilterValue(null);
        }
    }, []);

    const loadData = async () => {
        console.log(pageSize);
        const result = await api.get(path, {
            params: {
                page: page,
                limit: pageSize,
                ...orderServer,
                ...filterServer,
                ...pathParams,
            },
        });

        setRowCount(result.data.count);
        setRows(result.data.data);
    };

    React.useEffect(() => {
        let active = true;

        (async () => {
            setLoading(true);

            await loadData();

            if (!active) {
                return;
            }

            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [page, pageSize, sortModel, filterValue, filterServer]);

    React.useEffect(() => {
        if (reload) {
            (async () => {
                setLoading(true);

                await loadData();

                setLoading(false);
                setReload(false);
            })();
        }
    }, [reload]);

    return (
        <div
            className={classes.root}
            style={{
                height: modal ? "calc(100% - 160px)" : "calc(100% - 13rem)",
            }}
        >
            <Paper className={classes.paper}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pagination
                        page={page}
                        pageSize={pageSize}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowCount={rowCount}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        loading={loading}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        filterMode="server"
                        onFilterModelChange={handleFilterModelChange}
                    />
                </ThemeProvider>
            </Paper>
        </div>
    );
}
