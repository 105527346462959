import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Context } from "../contexts/AuthContext";

import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import User from "../pages/User";
import Pain from "../pages/Pain";
import Form from "../pages/Form";
import ElementType from "../pages/ElementType";
import Question from "../pages/Question";
import QuestionType from "../pages/QuestionType";
import ApplicationType from "../pages/ApplicationType";
import InterviewedType from "../pages/InterviewedType";
import FormType from "../pages/FormType";
import ProductType from "../pages/ProductType";
import Solution from "../pages/Solution";
import Tag from "../pages/Tag";
import Theme from "../pages/Theme";
import Hook from "../pages/Hook";
import Campaigns from "../pages/Campaigns";
import LgpdTerm from "../pages/LgpdTerm";
import MailModel from "../pages/MailModel";

import Page404 from "../pages/404";

function CustomRoute({ isPrivate, ...rest }) {
    const { loading, authenticated } = useContext(Context);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (isPrivate && !authenticated) {
        return <Redirect to="/login" />;
    }

    return <Route {...rest} />;
}

export default function Routes() {
    return (
        <Switch>
            <CustomRoute exact path="/login" component={Login} />
            <CustomRoute isPrivate path="/dashboard" component={Dashboard} />
            <CustomRoute isPrivate path="/users" component={User} />
            <CustomRoute isPrivate path="/forms" component={Form} />
            <CustomRoute isPrivate path="/campaigns" component={Campaigns} />
            <CustomRoute
                isPrivate
                path="/types/elements"
                component={ElementType}
            />
            <CustomRoute isPrivate path="/questions" component={Question} />
            <CustomRoute
                isPrivate
                path="/types/questions"
                component={QuestionType}
            />
            <CustomRoute
                isPrivate
                path="/types/applications"
                component={ApplicationType}
            />
            <CustomRoute
                isPrivate
                path="/types/interviewees"
                component={InterviewedType}
            />
            <CustomRoute
                isPrivate
                path="/types/products"
                component={ProductType}
            />
            <CustomRoute isPrivate path="/types/forms" component={FormType} />
            <CustomRoute isPrivate path="/pains" component={Pain} />
            <CustomRoute isPrivate path="/solutions" component={Solution} />
            <CustomRoute isPrivate path="/tags" component={Tag} />
            <CustomRoute isPrivate path="/themes" component={Theme} />
            <CustomRoute isPrivate path="/hook" component={Hook} />
            <CustomRoute isPrivate path="/lgpd_term" component={LgpdTerm} />
            <CustomRoute isPrivate path="/mail_models" component={MailModel} />
            <CustomRoute isPrivate path="*" component={Page404} />
        </Switch>
    );
}
