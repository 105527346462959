import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string()
    .min(2, "O campo precisa ter no mínimo 2 caracteres.")
    .required("Campo obrigatório."),
  form_id: Yup.object().shape({
    value: Yup.string().required("Campo obrigatório."),
    label: Yup.string(),
  }),
  spotlight: Yup.string().nullable(true),
  description: Yup.string().nullable(true),
  date_start: Yup.date().typeError("Campo obrigatório"),
  date_end: Yup.date()
  .nullable()
  .transform((curr, orig) => (orig === "" ? null : curr))
  .typeError("Data Inválida"),
  is_internal: Yup.boolean(),
  is_selectable_by_consultant: Yup.boolean(),
  is_selectable_by_self_service: Yup.boolean(),
  use_image: Yup.boolean(),
  is_active: Yup.boolean(),
  make_consult: Yup.boolean(),
  make_consult_message: Yup.string().nullable(true),
});
