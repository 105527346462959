import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { MdUndo, MdSave } from "react-icons/md";
import { SketchPicker } from "react-color";

import api from "../../services/api";

import schema from "./validation";
import Form from "../../components/Form";
import Field from "../../components/Field";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
    background: "#FFFFFF",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [pickerColor, setPikerColor] = React.useState("#FFFFFF");
  const [colorStyle, setColorStyle] = React.useState({
    background: "#FFFFFF",
  });
  React.useEffect(() => {
    (async () => {
      if (id) {
        try {
          const result = await api.get(`themes/${id}/`);

          setValue("name", result.data.name);
          setValue("color", result.data.color);
          if (result.data.color != null && result.data.color != undefined) {
            setColorStyle({
              background: `${result.data.color}`,
            });
          }
          setValue("is_active", result.data.is_active);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [id]);

  const onSubmit = async (data) => {
    if (id) {
      await api.patch(`themes/${id}/`, data);
    } else {
      await api.post(`themes/`, data);
    }

    props.history.push("/themes");
  };

  const handleButtonBack = () => {
    props.history.push("/themes");
  };
  const handleClickColor = () => {
    setDisplayColorPicker(true);
    return false;
  };

  const handleCloseColor = () => {
    setDisplayColorPicker(false);
    return false;
  };
  const handleChange = (color) => {
    console.log(color);
    setPikerColor(color);
    setValue("color", color.hex);
    setColorStyle({
      background: `${color.hex}`,
    });
    // this.setState({ color: color.rgb })
    return false;
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <Grid container item spacing={3} xs={12} className={classes.grid}>
          <Grid item xs={12}>
            <Field.Text
              control={control}
              name="name"
              label="Nome *"
              readOnly={true}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <Field.Hidden control={control} name="color" errors={errors} />
            Escolha a cor:&nbsp;
            <Field.Button
              onClick={handleClickColor}
              label=" &nbsp;"
              style={colorStyle}
            />
            {displayColorPicker ? (
              <div style={popover}>
                <div style={cover} onClick={handleCloseColor} />
                <SketchPicker color={pickerColor} onChange={handleChange} />
              </div>
            ) : null}
          </Grid>
          {id ? (
            <Grid item xs={12}>
              <Field.Boolean
                control={control}
                name="is_active"
                label="Ativo"
                errors={errors}
                color="primary"
              />
            </Grid>
          ) : (
            <Field.Hidden
              control={control}
              name="is_active"
              defaultValue={true}
            />
          )}
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Field.Button
            label="Voltar"
            onClick={handleButtonBack}
            startIcon={<MdUndo />}
          />
          <Field.Button
            type="submit"
            label="Salvar"
            color="primary"
            startIcon={<MdSave />}
          />
        </Grid>
        {id ? (
          <Grid container item xs={6} direction="row" justifyContent="flex-end"></Grid>
        ) : null}
      </Grid>
    </Form>
  );
};

export default Index;
