import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, IconButton, Box } from "@material-ui/core";
import { MdAdd, MdDelete } from "react-icons/md";

import api from "../../../../../../../services/api";

import Field from "../../../../../../../components/Field";
import Headers from "../../../../../../../components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 0,
  },
}));

const Index = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    theme,
    themeIndex,
    themActive,
    questionSelected,
    setQuestionSelected,
    setShowQuestionModal,
    setQuestionModalProps,
  } = props;
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const {
    fields: questionsFields,
    append: questionsAppend,
    // insert: questionsInsert,
    remove: questionsRemove,
  } = useFieldArray({
    control,
    name: `questions.${themeIndex}`,
    keyName: "uuid",
  });

  React.useEffect(() => {
    (async () => {
      try {
        const resultQuestion = await api.get(
          `forms/${id}/themes/${theme.key}/questions/`
        );
        let questions = [];
        resultQuestion.data.data.forEach((value) => {
          value.value = parseInt(value.value);
          questions.push(value);
        });
        setValue(`questions.${themeIndex}`, questions);
        updateSumValue();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [theme]);

  React.useEffect(() => {
    (async () => {
      if (questionSelected) {
        if (questionSelected.theme === theme.key) {
          try {
            const resultQuestion = await api.post(
              `forms/${id}/pages/${questionSelected.theme}/questions/`,
              {
                order: questionsFields.length + 1,
                uuid: questionSelected.key,
              }
            );
            // console.log(resultQuestion);
            let question = [];
            resultQuestion.data.forEach((request) => {
              request.value = parseInt(request.value);
              request.question.value = parseInt(request.question.value);
              question.push(request);
            });
            questionsAppend(question);
            setQuestionSelected(null);
          } catch (e) {
            console.error(e);
          }
        }
      }
    })();
  }, [questionSelected]);

  const handleQuestionsAppend = () => {
    // const order = questionsFields.length + 1;

    setQuestionModalProps({
      theme: theme,
    });

    setShowQuestionModal(true);
  };

  const handleQuestionsRemove = async (index) => {
    const question = questionsFields[index].uuid;

    await api.delete(`forms/${id}/themes/${theme.key}/questions/${question}/`);

    questionsRemove(index);
    updateSumValue();
  };

  const updateSumValue = () => {
    let themes = getValues(`questions`);
    let sum = 0;
    themes.forEach((questions) => {
      questions.forEach((question) => {
        sum += parseInt(question.value);
      });
    });
    setValue("value", sum);
  };

  const change = (e) => {

    let value = e.target.value;
    if (e.target.value > 100) {
      value = 100;
    }
    if(e.target.value < 0){
        value = 0;
    }
    setValue(e.target.name, value);
    
    updateSumValue();
  };

  return (
    <Paper
      className={classes.paper}
      style={{
        display: !themActive ? "none" : "block",
        width: "100%",
      }}
    >
      <Grid item xs={12}>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={8}>
            <Headers.Grid>Tema: {theme.label}</Headers.Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Field.Button
              label="Adicionar Pergunta"
              color="primary"
              onClick={handleQuestionsAppend}
              startIcon={<MdAdd />}
            />
          </Grid>
        </Grid>
        {questionsFields.map((question, index) => (
          <Paper className={classes.paper} key={question.question.uuid}>
            <Grid container spacing={1} item xs={12}>
              <Field.Hidden
                control={control}
                name={`questions.${themeIndex}.${index}.uuid`}
                defaultValue={question.uuid}
              />
              <Field.Hidden
                control={control}
                name={`questions.${themeIndex}.${index}.order`}
                defaultValue={question.order}
              />
              <Grid item xs={1}>
                <Field.Boolean
                  control={control}
                  name={`questions.${themeIndex}.${index}.is_active`}
                  label=""
                  errors={errors}
                  color="primary"
                  defaultValue={question.is_active}
                />
              </Grid>
              <Grid item xs={2}>
                <Field.NumberMask
                  control={control}
                  name={`questions.${themeIndex}.${index}.value`}
                  label=""
                  errors={errors}
                  defaultValue={question.value}
                  onBlur={change}
                  mask={{
                    allowDecimal: false,
                    decimalSymbol: "",
                    requireDecimal: false,
                    integerLimit: 3,
                  }}
                />
                <Box fontStyle="italic">
                  Digite o valor dessa questão de 0 a 100.
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={6}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>{question.question.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Field.Boolean
                  control={control}
                  name={`questions.${themeIndex}.${index}.is_required`}
                  label="Obrigatória"
                  errors={errors}
                  color="primary"
                  defaultValue={question.is_required}
                />
              </Grid>
              <Grid
                container
                item
                xs={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => handleQuestionsRemove(index)}
                >
                  <MdDelete size={24} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Paper>
  );
};

export default Index;
