import styled from "styled-components";

const Content = styled.span`
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
`;

export default Content;
