import React from "react";
import { CgMenu, CgLogOff } from "react-icons/cg";

import { Context } from "../../../../contexts/AuthContext";

import Headers from "../../../../components/Header";

const Index = (props) => {
    const { user, handleLogout } = React.useContext(Context);
    const { isOpen, onOpen, onClose } = props;

    return (
        <Headers.Header isOpen={isOpen}>
            <Headers.Div>
                <Headers.Div isControl>
                    <CgMenu size="32" onClick={isOpen ? onClose : onOpen} />
                </Headers.Div>
                <Headers.Div float left>
                    <Headers.Ul clearfix>
                        <Headers.Li isIcons float left>
                            Formulários
                        </Headers.Li>
                    </Headers.Ul>
                </Headers.Div>
                <Headers.Div float right>
                    <Headers.Ul clearfix>
                        {isOpen ? (
                            <Headers.Li isIcons float left>
                                {user.name}
                            </Headers.Li>
                        ) : null}
                        <Headers.Li isIcons float left isIconsControl>
                            <CgLogOff size="32" onClick={handleLogout} />
                        </Headers.Li>
                    </Headers.Ul>
                </Headers.Div>
            </Headers.Div>
        </Headers.Header>
    );
};

export default Index;
