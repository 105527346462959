import styled from "styled-components";

const Section = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;

    background: transparent;

    margin: 0.9375rem;
    margin-bottom: 0;
    padding: 0 0.9375rem;

    height: 3.125rem;
`;

const List = styled(Section)``;

const Form = styled(Section)``;

const Delete = styled(Section)``;

const Sections = {
    Section,
    List,
    Form,
    Delete,
};

export default Sections;
