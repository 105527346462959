import styled from "styled-components";

const Input = styled.input`
    display: block;
    width: 17px;
    height: 34px;
    padding: 6px 12px;
    border-radius: 4px;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
`;

export default Input;
